import { Component, ViewContainerRef } from "@angular/core";
import { ToastsManager } from "ng2-toastr";

@Component({
  selector: "app-root",
  template: `<ng-progress></ng-progress>
             <router-outlet></router-outlet>`
})
export class AppComponent {
  title = "CIOT";

  constructor(
    private vRef: ViewContainerRef,
    private toastr: ToastsManager) {
    this.toastr.setRootViewContainerRef(vRef);
  }
}

