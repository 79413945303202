import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChildren} from '@angular/core';
import {FormBuilder, FormControlName, FormGroup, Validators} from "@angular/forms";  
import {Router} from "@angular/router";
import { Subject } from "rxjs/Subject";

import {Observable} from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

import {GenericValidator} from "../../../commom/generic.form.validator";
import {Pessoa} from "../../../ciot/models/pessoa";
import {Cidade} from "../../models/cidade";
import {RetornoCidades} from "../../models/cidade";
import {AutoCompleteService} from '../../services/autocomplete.service';
import {CiotService} from '../../services/ciot.service';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {ViagemComponent} from '../viagem/viagem.component';  
 
@Component({
    selector: 'app-contratante',
    templateUrl: './contratante.component.html',
    styles: []
})
export class ContratanteComponent implements OnInit, AfterViewInit {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    public errors: any[] = [];
    public contratanteForm: FormGroup;
    public veiculo: Pessoa;
    public displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;
    private title;
    public value: Date = new Date(2000, 2, 10);
    public cidade: RetornoCidades;
    private subject = new Subject<boolean>();
    maskCpfCnpj = '';

    // AutoComplete fields
    cidadeList: Array<Cidade> = [];
    noResult = false;
    cidadeCompleterText: string;

    @Input() CpfCnpjInteressado: string;
    @Input() tipoViagem: string;
    @Input() viagemComponent: ViagemComponent;

    constructor(private fb: FormBuilder,
        private ciotService: CiotService,
        private autoCompleteService: AutoCompleteService,
        private router: Router) {

        this.ciotService.consultarCidades("")
            .subscribe(
                result => {
                    this.cidadeList = result;
                },
                error => { }
            );

        this.validationMessages = {
            cpfCnpj: {
                required: 'O CPF/CNPJ é obrigatório.'
            },
            nome: {
                required: 'O nome é obrigatório.'
            },
            cep: {
                required: 'O CEP é obrigatório.',
            },
            logradouro: {
                required: 'O logradouro é obrigatório.',
            },
            cidade: {
                required: 'A cidade é obrigatória.',
            },

        };

        this.genericValidator = new GenericValidator(this.validationMessages);
    }

    // AutoComplete métodos
    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
        this.cidade = undefined;
    }

    onSelectCidade(event: TypeaheadMatch): void {
        this.cidade = event.item;
    }

    ngOnInit() {
        this.contratanteForm = this.fb.group({
            cpfCnpj: [''],
            nome: ['', [Validators.required]],
            cep: ['', [Validators.required]],
            logradouro: ['', [Validators.required]],
            cidade: ['', [Validators.required]],
            rntrc: ['',],
            nomeFantasia: ['',],
            numero: ['',],
            bairro: ['',],
            complemento: ['',],
            telefone: ['',],
            email: ['',]
        });

        this.contratanteForm.controls['cpfCnpj'].setValue(this.CpfCnpjInteressado);
        this.contratanteForm.value.cpfCnpj = this.CpfCnpjInteressado;
        this.contratanteForm.controls['cpfCnpj'].disable();

        this.contratanteForm.get('cpfCnpj').valueChanges.map(valor=>valor.length).subscribe(length=>{
            if(length>11){
              this.maskCpfCnpj = '00.000.000/0000-00'
            } else {
             this.maskCpfCnpj = '000.000.000-000'
            }
      
            if (length < 11 && this.maskCpfCnpj == '00.000.000/0000-00') {
             this.maskCpfCnpj = '000.000.000-000'
            }
          })

          document.getElementById('idRntrc').hidden = true;
    } 

    //Gambi do matias para desabilitar/habilitar o input
    isVisible(resp): void {
        if(resp) {
            document.getElementById('idRntrc').hidden = true;
        } else {
            document.getElementById('idRntrc').hidden = false;  
        } 
    } 

    ngAfterViewInit(): void {
        let controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

        Observable.merge(...controlBlurs).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.contratanteForm);
        });
    }

    ngFocus() {
        let inputField: HTMLElement = <HTMLElement>document.querySelectorAll('.dialog input')[0];
        inputField && inputField.focus();
    }

    onError(serviceReturn) {
        this.errors = Object.assign([], serviceReturn.error.errors);
    }

    onSaveComplete(): void {
        this.contratanteForm.reset();
    }
}
