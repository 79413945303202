import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChildren} from '@angular/core';
import {FormBuilder, FormControlName, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";

import {Observable} from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

import {GenericValidator} from "../../commom/generic.form.validator";
import {CiotService} from "../../ciot/services/ciot.service";
import {Subscription} from 'rxjs/Subscription';
import {DatePipe} from '@angular/common';
import {Viagem} from '../models/viagem';
import {Cidade} from '../models/cidade';
import {NaturezaCarga} from '../models/natureza-carga';
import {ConsultarSituacaoCiotRequest} from "../models/consulta-ciot/consultar-situacao-ciot-request";
import {CiotResponse} from "../models/consulta-ciot/consulta-ciot-response";
import {Ciot} from "../models/ciot";
import {DadosEncerramento} from '../models/encerramento/dados-encerramento';
import {
    EncerrarOperacaoTransporteRequest,
    EncerrarOperacaoTransporteResponse,
    ValoresEfetivos,
    ViagensOperacaoTransporte
} from '../models/encerramento/encerrar-operacao-transporte';
import {ToastsManager} from 'ng2-toastr';
import {AutoCompleteService} from '../services/autocomplete.service';
import {Mensagens, TipoViagem} from '../util/enums';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';

import {BsDatepickerConfig, BsLocaleService} from 'ngx-bootstrap/datepicker';
import {defineLocale, listLocales} from 'ngx-bootstrap/chronos'
import {ptBrLocale} from 'ngx-bootstrap/locale';
import { objEncerrar } from '../models/encerramento/obj-encerrar';

@Component({
    selector: 'app-encerramento',
    templateUrl: './encerramento.component.html',
    styles: []
})
export class EncerramentoComponent implements OnInit, AfterViewInit {

    locale = ptBrLocale;
    locales = listLocales();

    @ViewChildren(FormControlName, {read: ElementRef}) formInputElements: ElementRef[];

    bsConfig: Partial<BsDatepickerConfig>;

    public errors: any[] = [];
    public encerramentoForm: FormGroup;
    public viagemForm: FormGroup;
    public displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;
    private title;
    public sub: Subscription;
    public value: Date = new Date(2000, 2, 10);
    public viagemList: Array<Viagem> = [];
    public viagem: Viagem;
    // public cidadeOrigem: Cidade;
    // public cidadeDestino: Cidade;
    public naturezaCarga: NaturezaCarga;
    private request: EncerrarOperacaoTransporteRequest;
    public ciotEncerramento: Ciot;
    private viagensOperacaoTransporte: ViagensOperacaoTransporte;
    public tipoViagemDescricao: string;
    public tipoViagem: number;

    ciotId: string;
    senha: string;
    @Output() resetForm = new EventEmitter();
    public codigoCidadeOrigem: string = "";
    public codigoCidadeDestino: string = "";
    public codigoNatureza: string = "";

    // AutoComplete fields
    cidadeList: Array<Cidade> = [];
    naturezaCargaList: Array<NaturezaCarga> = [];

    noResultOrigem = false;
    noResultDestino = false;
    noResultNatureza = false;

    cidadeOrigemCompleterText: string;
    cidadeDestinoCompleterText: string;
    naturezaCompleterText: string;

    dataInicio = new Date();
    dataFim = new Date();

    constructor(private fb: FormBuilder,
                private service: CiotService,
                private autoCompleteService: AutoCompleteService,
                private router: Router,
                private route: ActivatedRoute,
                private toastr: ToastsManager,
                private localeService: BsLocaleService) {

        defineLocale("ptbr", ptBrLocale)

        this.bsConfig = Object.assign({}, {containerClass: "theme-red"});
        this.localeService.use("ptbr");

        // this.service.consultarCidades("")
        //     .subscribe(
        //         result => {
        //             this.cidadeList = result;
        //         },
        //         error => {
        //         }
        //     );
        this.service.consultarNaturezaCarga("")
            .subscribe(
                result => {
                    this.naturezaCargaList = result;
                },
                error => {
                }
            );

        this.validationMessages = {
            // cidadeOrigem: {
            //     required: 'A cidade origem do contratante é obrigatório.'
            // },
            // cidadeDestino: {
            //     required: 'A cidade destino é obrigatório.',
            // },
            naturezaCarga: {
                required: 'A natureza da carga é obrigatório.',
            },
            valorFrete: {
                required: 'O valor do frete é obrigatório.',
            },
            quantidadeTarifas: {
                required: 'A quantidade de tarifas é obrigatório.',
            },
            valorTotalTarifas: {
                required: 'O valor total das tarifas é obrigatório.',
            },
            placa: {
                required: 'O valor total das tarifas é obrigatório.',
            },
            rntrcVeiculo: {
                required: 'O valor total das tarifas é obrigatório.',
            },
            pesoCarga: {
                required: 'O peso da carga é obrigatório.',
            }
        };
        this.genericValidator = new GenericValidator(this.validationMessages);
    }

    // AutoComplete métodos
    // typeaheadNoResultsOrigem(event: boolean): void {
    //     this.noResultOrigem = event;
    //     this.cidadeOrigem = undefined;
    // }

    // typeaheadNoResultsDestino(event: boolean): void {
    //     this.noResultDestino = event;
    //     this.cidadeDestino = undefined;
    // }

    typeaheadNoResultsNatureza(event: boolean): void {
        this.noResultNatureza = event;
        this.naturezaCarga = undefined;
    }

    // onSelectCidadeOrigem(event: TypeaheadMatch): void {
    //     this.cidadeOrigem = event.item;
    // }

    // onSelectCidadeDestino(event: TypeaheadMatch): void {
    //     this.cidadeDestino = event.item;
    // }

    onSelectNatureza(event: TypeaheadMatch): void {
        this.naturezaCarga = event.item;
    }

    consultarEncerramento() {
        this.ciotEncerramento = new Ciot();
        let a = new ConsultarSituacaoCiotRequest();
        a.ciot = this.ciotId;
        a.senhaAlteracao = this.senha;

        this.service.consultarCiot(a)
            .subscribe(
                response => this.preencherDadosInformativos(response, a.ciot, a.senhaAlteracao),
                error => {
                    this.onError(error)
                }
            );
    }

    preencherDadosInformativos(ciotResponse: CiotResponse, ciotId: string, senha: string): void {

        this.ciotEncerramento.totalImposto = ciotResponse.Retorno.TotalImposto;
        this.ciotEncerramento.rntrc = ciotResponse.Retorno.RntrcProprietario;
        this.ciotEncerramento.contratado = ciotResponse.Retorno.NomeProprietario;
        this.ciotEncerramento.valorFrete = ciotResponse.Retorno.ValorFrete;
        this.ciotEncerramento.valorFretePago = ciotResponse.Retorno.ValorFrete;
        this.ciotEncerramento.totalPedagio = ciotResponse.Retorno.TotalPegadio;
        this.ciotEncerramento.encerrada = ciotResponse.Retorno.Encerrado;
        this.ciotEncerramento.valorDespesas = ciotResponse.Retorno.ValorDespesas;
        this.tipoViagemDescricao = Number(TipoViagem.Padrao) == ciotResponse.Retorno.TipoViagem ? Mensagens.PADRAO : Mensagens.TAC_AGREGADO;
        this.tipoViagem = ciotResponse.Retorno.TipoViagem

        var objRequest = new objEncerrar();
        objRequest.ciotId = ciotId;
        objRequest.senha = senha;
        this.service.consultarEncerramento(objRequest)
            .subscribe(
                result => this.preencherDadosEncerrar(result))
        error => {
            this.onError(error)
        }
    }

    preencherDadosEncerrar(dadosEncerramento: DadosEncerramento): void {
        this.encerramentoForm.markAsDirty; 
        this.dataInicio = new Date(new DatePipe('en-US').transform(String(dadosEncerramento.Retorno.DataInicioViagem).substr(0,10), "MM/dd/yyyy"));
        var dataFinal = new Date(new DatePipe('en-US').transform(String(dadosEncerramento.Retorno.DataFimViagem).substr(0,10), "MM/dd/yyyy"));
        var dataAgora = new Date();

        if(dataFinal > dataAgora)
        this.dataFim = dataAgora;
        else
        this.dataFim = dataFinal;


        // this.codigoCidadeOrigem = dadosEncerramento.codigoMunicipioOrigem
        // this.codigoCidadeDestino = dadosEncerramento.codigoMunicipioDestino;

        this.codigoNatureza = dadosEncerramento.Retorno.CodigoNaturezaCarga;
        this.ciotEncerramento.ciot = dadosEncerramento.Retorno.CIOT;
        this.ciotEncerramento.pesoCarga = dadosEncerramento.Retorno.PesoCarga ? dadosEncerramento.Retorno.PesoCarga : 0;
        this.ciotEncerramento.senha = dadosEncerramento.Retorno.SenhaAlteracao;
        this.ciotEncerramento.tipoViagem = dadosEncerramento.Retorno.TipoViagem;

        this.ciotEncerramento.quantidadeTarifas = dadosEncerramento.Retorno.QuantidadeTarifas;
        this.ciotEncerramento.valorTotalTarifas = dadosEncerramento.Retorno.ValorTarifas;
        if (Number(TipoViagem.Padrao) == dadosEncerramento.Retorno.TipoViagem) {
            this.encerramentoForm.controls['totalImposto'].disable();
            this.encerramentoForm.controls['totalPedagio'].disable();
            this.encerramentoForm.controls['valorFretePago'].disable();
            this.encerramentoForm.controls['valorDespesas'].disable();
        }

        this.encerramentoForm.setValue(this.ciotEncerramento);

        this.service.consultarNaturezaCargaById(this.codigoNatureza)
            .subscribe(
                result => {
                    this.naturezaCarga = result[0];
                    if(this.naturezaCarga != undefined)
                        this.naturezaCompleterText = this.naturezaCarga.Retorno.DESCRICAO;
                })
        error => {
            this.onError(error)
        }
    }

    adicionarViagem() {
        if (this.viagemForm.dirty && this.viagemForm.valid) {
            this.viagem = this.viagemForm.value;

            // this.viagem.cidadeOrigem = this.cidadeOrigem;
            // this.viagem.cidadeDestino = this.cidadeDestino;
            this.viagem.naturezaCarga = this.naturezaCarga;

            for (let index = 0; index < this.viagemList.length; index++) {

                if (this.viagemList[index].cidadeDestino.Retorno.ID_CIDADE == this.viagem.cidadeDestino.Retorno.ID_CIDADE
                    && this.viagemList[index].cidadeOrigem.Retorno.ID_CIDADE == this.viagem.cidadeOrigem.Retorno.ID_CIDADE
                    && this.viagemList[index].naturezaCarga.Retorno.CODIGO == this.viagem.naturezaCarga.Retorno.CODIGO) {
                    this.toastr.error("Esta viagem já foi adicionado", "Oops!");
                    return;
                }
            }


            this.viagemList.push(this.viagem);
            this.viagemForm.reset();
            this.encerramentoForm.markAsDirty();
            this.encerramentoForm.markAsDirty;

            this.cidadeDestinoCompleterText = null;
            // this.cidadeDestino = null

            this.cidadeOrigemCompleterText = null;
            // this.cidadeOrigem = null

            this.naturezaCompleterText = null;
            this.naturezaCarga = null
        }
    }

    removerViagem(viagem) {
        this.viagemList
        for (var i = 0; i < this.viagemList.length; i++) {
            if (this.viagemList[i].cidadeOrigem == viagem.cidadeOrigem && this.viagemList[i].cidadeDestino == viagem.cidadeDestino) {
                var index = this.viagemList.indexOf((this.viagemList[i]));
                this.viagemList.splice(index, 1)
            }
        }
        this.viagemForm.reset;
    }

    ngOnInit() {
        this.encerramentoForm = this.fb.group({
            ciot: ['', [Validators.required]],

            rntrc: ['',],
            contratado: ['',],
            valorFrete: ['',],
            totalImposto: ['',],
            totalPedagio: ['',],
            tipoViagem: ['',],
            senha: ['',],
            encerrada: ['',],
            pesoCarga: ['', [Validators.required]],
            quantidadeTarifas: ['',],
            valorTotalTarifas: ['',],
            valorFretePago: ['',],
            valorDespesas: ['',]

            // documentoContratante: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(14)]],
        });
        this.viagemForm = this.fb.group({
            pesoCarga: ['', [Validators.required, Validators.maxLength(5)]],
            qtdViagens: ['', [Validators.required]],
        });
        this.viagem = new Viagem();

        this.sub = this.route.params.subscribe(
            params => {
                this.ciotId = params['ciot'];
                this.senha = params['senha'];
                this.consultarEncerramento();
            });
    }

    ngAfterViewInit(): void {
        let controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

        Observable.merge(...controlBlurs).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.encerramentoForm);
        });
    }

    encerrar() {
        this.request = new EncerrarOperacaoTransporteRequest();
        this.request.valoresEfetivos = new ValoresEfetivos();
        this.request.ciot = this.ciotId;
        this.request.PesoCargaTotal  = this.encerramentoForm.value.pesoCarga;
        this.request.valoresEfetivos.quantidadeTarifas = this.encerramentoForm.value.quantidadeTarifas;
        this.request.senhaAlteracao = this.senha;
        this.request.valoresEfetivos.valorTarifas = this.encerramentoForm.value.valorTotalTarifas;

        let viagensOperacaoTransporteList = new Array<ViagensOperacaoTransporte>();
        this.viagemList.forEach(element => {
            let viagensOperacaoTransporte = new ViagensOperacaoTransporte();
            viagensOperacaoTransporte.codigoMunicipioOrigem = element.cidadeOrigem.Retorno.ID_CIDADE;
            viagensOperacaoTransporte.codigoMunicipioDestino = element.cidadeDestino.Retorno.ID_CIDADE;
            viagensOperacaoTransporte.codigoNaturezaCarga = element.naturezaCarga.Retorno.CODIGO;
            viagensOperacaoTransporte.pesoCarga = element.pesoCarga;
            viagensOperacaoTransporte.quantidadeViagens = element.qtdViagens;
            viagensOperacaoTransporteList.push(viagensOperacaoTransporte);
        });

        this.request.viagensOperacaoTransporte = viagensOperacaoTransporteList;

        this.service.encerrarOperacaoTransporte(this.request)
            .subscribe(
                response => this.onSaveComplete(response),
                error => {
                    this.onError(error)
                }
            );
    }

    onSaveComplete(response: EncerrarOperacaoTransporteResponse): void {
        if (response.Retorno.Sucesso) {
            this.router.navigate(['/']).then(() => this.toastr.success('CIOT encerrado com sucesso!', Mensagens.SUCESSO));
        } else {
            if (response.Retorno.Excecao != null) {
                this.toastr.error(response.Retorno.Excecao.Mensagem, Mensagens.OOPS)
            } else {
                this.toastr.error(response.Mensagem, Mensagens.OOPS)

            }
        }
    }

    onError(serviceReturn) {
        this.errors = Object.assign([], serviceReturn.error.errors);
    }

    voltar() {
        this.router.navigate(['/']);
    }
}