import {Component, OnInit, ViewChild} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {DeclararOperacaoTransporte} from './models/declarar-operacao-transporte';
import {CiotService} from './services/ciot.service'; 
import {ToastsManager} from 'ng2-toastr';
import {CiotTabsService} from './services/ciot-tabs.service';
import {Mensagens} from './util/enums';
import {TabsetComponent} from 'ngx-bootstrap/tabs';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-ciot',
  templateUrl: './ciot.component.html'
})
export class CiotComponent implements OnInit {

  @ViewChild('staticTabs') staticTabs: TabsetComponent;

  static instance : CiotComponent;
  // public get staticTabs(): TabsetComponent{
  //   return CiotComponent.staticTabs;
  // }
  public errors: any[] = [];
  public declaracao: DeclararOperacaoTransporte;
  public sub: Subscription;

  hide: string = "display:none"
  public CpfCnpjTransportador: string;
  public RNTRCTransportador: string;
  public NomeRazaoSocialTransportador: string;
  public CpfCnpjInteressado: string;
  public IsDisabled: boolean = true;

  constructor(private ciotService: CiotService,
    private ciotTabsService: CiotTabsService,
    private toastr: ToastsManager,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    CiotComponent.instance= this;

    this.ciotTabsService.isVisible().subscribe(show => {
        if (show) {
            CiotComponent.instance.enableTabs();
            this.IsDisabled = false;
        } else {
            CiotComponent.instance.disableTabs();
            this.IsDisabled = true;
        }
    })
    this.sub = this.route.params.subscribe(
        params => {
            this.CpfCnpjTransportador = params['CpfCnpjTransportador'];
            this.RNTRCTransportador = params['RNTRCTransportador'];
            this.NomeRazaoSocialTransportador = params['NomeRazaoSocialTransportador'];
            this.CpfCnpjInteressado = params['CpfCnpjInteressado'];
        });
  }

  avancar(evento) {
    this.ciotService.declararOperacaoTransporte(this.declaracao)
      .subscribe(
        result => { this.onSaveComplete() },
        error => {
          this.onError(error);
        });
  }

  onError(serviceReturn) {
    this.errors = Object.assign([], serviceReturn.error.errors);
    this.toastr.error(Mensagens.NAO_FOI_POSSIVEL_DECLARAR_CIOT, Mensagens.OOPS);
  }

  onSaveComplete(): void {
    this.router.navigate(['/']);
    this.toastr.success(Mensagens.CIOT_GERADO_SUCESSO, Mensagens.SUCESSO);
  }

  public enableTabs() {
    this.staticTabs.tabs[2].disabled = false;
    this.staticTabs.tabs[3].disabled = false;
    this.staticTabs.tabs[4].disabled = false;
  }

  public disableTabs() {
    this.staticTabs.tabs[2].disabled = true;
    this.staticTabs.tabs[3].disabled = true;
    this.staticTabs.tabs[4].disabled = true;
  }
}