import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";
import { NgAutocompleteComponent } from "ng-auto-complete";

@Injectable()
export class AutoCompleteService {

    private subject = new Subject<string>();
    private subject2 = new Subject<string>();
    private subject3 = new Subject();
    private subject4 = new Subject<NgAutocompleteComponent>();

    enviaMensagem(texto: string) {
        this.subject.next(texto);
    }

    enviaUf(uf: string) {
        this.subject2.next(uf);
    }

    enviaResetInput() {
        this.subject3.next();
    }

    enviaCompleter(completer: NgAutocompleteComponent) {
        this.subject4.next(completer);
    }

    recebeMensagem(): Observable<string> {
        return this.subject.asObservable();
    }

    recebeUF(): Observable<string> {
        return this.subject2.asObservable();
    }

    recebeResetInput(): Observable<any> {
        return this.subject3.asObservable();
    }

    recebeCompleter(): Observable<NgAutocompleteComponent> {
        return this.subject4.asObservable();
    }
} 