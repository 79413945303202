import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";
import { NgAutocompleteComponent } from "ng-auto-complete";

@Injectable()
export class AutoCompleteCodigoIBGEService {

    private subject = new Subject<NgAutocompleteComponent>();

    enviaCompleter(completer: NgAutocompleteComponent) {
        this.subject.next(completer);
    }

    recebeCompleter(): Observable<NgAutocompleteComponent> {
        return this.subject.asObservable();
    }
}