import { Component, OnInit, ViewEncapsulation, AfterViewInit, ElementRef, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName } from "@angular/forms";
import { Router } from "@angular/router";

import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

import { GenericValidator } from "../../commom/generic.form.validator";
import { CiotService } from '../../ciot/services/ciot.service';
import { CancelarOperacaoTransporte } from '../../ciot/models/cancelar-operacao-transporte';
import { ToastsManager } from 'ng2-toastr';
import { Mensagens } from '../../ciot/util/enums';
import { ReCaptcha } from '../../ciot/models/ReCaptcha';
import { RecaptchaService } from '../../ciot/services/recaptcha.service';
import { ConsultarSituacaoCiotRequest } from '../../ciot/models/consulta-ciot/consultar-situacao-ciot-request';

@Component({
  selector: 'app-cancelar',
  templateUrl: './cancelar.component.html'
})
export class CancelarComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  public errors: any[] = [];
  public cancelarForm: FormGroup;
  public modelCancelar: CancelarOperacaoTransporte;
  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  private validRecaptcha: boolean;
  private recaptchaIsValid: boolean;

  constructor(private fb: FormBuilder,
    private toastr: ToastsManager,
    vRef: ViewContainerRef,
    private service: CiotService,
    private router: Router,
    private recaptchaService: RecaptchaService) {

    this.validationMessages = {
      ciotCancelar: {
        required: 'O CIOT é obrigatório.'
      },
      senhaCancelar: {
        required: 'A senha é obrigatória.'
      },
      motivo: {
        required: 'O motivo é obrigatorio.'
      }
    };

    this.genericValidator = new GenericValidator(this.validationMessages);
    this.modelCancelar = new CancelarOperacaoTransporte();
  }

  ngOnInit() {
    this.cancelarForm = this.fb.group({
      ciotCancelar: ['', [Validators.required]],
      senhaCancelar: ['', [Validators.required]],
      motivo: ['', []]
    });
    this.recaptchaService.isValid().subscribe(valid => this.validRecaptcha = valid); 
  }

  ngAfterViewInit(): void {
    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    Observable.merge(...controlBlurs).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.cancelarForm);
    });
  }

  cancelar() {
    if (this.cancelarForm.dirty && this.cancelarForm.valid) {
      // if (this.validRecaptcha) {
        let requestConsultar = new ConsultarSituacaoCiotRequest();
        requestConsultar.ciot = this.cancelarForm.value.ciotCancelar;
        requestConsultar.senhaAlteracao = this.cancelarForm.value.senhaCancelar;

        this.service.consultarCiot(requestConsultar)
          .subscribe(response => {
              if (response.Sucesso) {
                  var oneDay=1000*60*60*24;
                  var dataAtualMs = new Date().getTime();
                  var stringDate = String(response.Retorno.DataInicioFrete).substring(0, 10);
                  var dataFinalMs = new Date(stringDate).getTime();

                  // Calculate the difference in milliseconds
                  var differenceMs = dataAtualMs - dataFinalMs;

                  // Convert back to days and return
                  var dias = Math.round(differenceMs/oneDay);

                  if (dias > 5){
                    this.toastr.error(Mensagens.DECLARACAO_INFORMADA_CANCELAMENTO_5DIAS, "Oops");
                    return
                  }

                  this.modelCancelar = new CancelarOperacaoTransporte();
                  this.modelCancelar.ciot = this.cancelarForm.value.ciotCancelar;
                  this.modelCancelar.senhaAlteracao = this.cancelarForm.value.senhaCancelar;
                  this.modelCancelar.motivoCancelamento = this.cancelarForm.value.motivo;

                  this.service.cancelarOperacaoTransporte(this.modelCancelar)
                    .subscribe(
                    result => { this.onSaveComplete(result) },
                    error => {
                    this.onError(error);
                  });
              }
            }) 
      // } else {
      //   this.toastr.error(Mensagens.INFORME_CAMPO_NAO_SOU_ROBO, Mensagens.OOPS)
      //   return;
      // }
    }
  }

  onError(serviceReturn) {
    this.errors = Object.assign([], serviceReturn.error.errors);
    this.toastr.error(Mensagens.NAO_POSSIVEL_CANCELAR_CIOT, Mensagens.OOPS);
  }

  onSaveComplete(result): void {
    if (result.Retorno.Sucesso) {
      this.cancelarForm.reset();
      this.errors = [];
      this.toastr.success('CIOT cancelado com sucesso!', Mensagens.SUCESSO);
    } else {
      if(result.Retorno.Excecao != null)
        this.toastr.error(result.Retorno.Excecao.Mensagem, Mensagens.OOPS);
      else
      this.toastr.error(result.Retorno.Mensagem, Mensagens.OOPS);
    }
  }

  resolved(captchaResponse: string) {
    if(captchaResponse) {
      this.service.validarTokenReCaptcha(captchaResponse)
      .subscribe(
        result => {
          this.onValidRecaptcha(result)
        },
        error => {
          this.onErrorRecaptcha(error);
        });
    } else {
      this.toastr.error(Mensagens.ERRO_VALIDAR_RECAPTCHA, Mensagens.OOPS);
      this.recaptchaService.setValid(false);
    }
  }

  onValidRecaptcha(result: ReCaptcha) {
    if (!result.Sucesso) {
      this.toastr.error(Mensagens.ERRO_VALIDAR_RECAPTCHA, Mensagens.OOPS);
      this.recaptchaService.setValid(false);
    } else {
      this.recaptchaService.setValid(true);
    }
  }

  onErrorRecaptcha(error) {
    this.toastr.error(Mensagens.ERRO_CONSULTAR_INFORMACOES_RECAPTCHA, Mensagens.OOPS);
  }
}
