// https://github.com/sengirab/ngAutocomplete

import { Component, ViewChild, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { CreateNewAutocompleteGroup, SelectedAutocompleteItem, NgAutocompleteComponent } from "ng-auto-complete";
import { CiotService } from "../../ciot/services/ciot.service";
import { ArrayObservable } from "rxjs/observable/ArrayObservable";
import { Cidades } from "../../ciot/models/CodigoIBGE";
import { AutoCompleteService } from "../../ciot/services/autocomplete.service";
import { ToastsManager } from "ng2-toastr";
import { AutoCompleteCodigoIBGEService } from "../../ciot/services/autocomplete-codIBGE.service";

@Component({
  selector: 'app-autocomplete-codIBGE',
  templateUrl: './autocomplete-codIBGE.component.html',
  styles: []
})
export class AutocompleteCodigoIBGEComponent implements OnInit {

  @ViewChild(NgAutocompleteComponent) public completer: NgAutocompleteComponent;

  static cidadeIBGEList: Array<Cidades> = [];
  static inicializado: boolean = false;

  @Output() choosedCidadeIBGE = new EventEmitter();

  public group = [
    CreateNewAutocompleteGroup(
      "Selecione",
      'completerCodigoIBGE',
      AutocompleteCodigoIBGEComponent.cidadeIBGEList,
      { titleKey: 'descricao', childrenKey: null }
    ),
  ];

  constructor(private service: CiotService,
    private autoCompleteService: AutoCompleteService,
    private autoCompleteCodigoIBGEService: AutoCompleteCodigoIBGEService) {
  }

  ngOnInit(): void {
    this.autoCompleteService.recebeResetInput().subscribe(component => {
      this.completer.ResetInputs();
    },
      (error) => {
      });
    this.autoCompleteService.enviaCompleter(this.completer);
    this.autoCompleteCodigoIBGEService.enviaCompleter(this.completer);
  }

  consultarCidades(descricao: string): void {
    if (!AutocompleteCodigoIBGEComponent.inicializado) {
      AutocompleteCodigoIBGEComponent.inicializado = true;
      this.service.consultarCidadesByIBGE(descricao)
        .subscribe(
          result => {
            AutocompleteCodigoIBGEComponent.cidadeIBGEList = result.Retorno;
            if (this.completer != undefined) {
              this.completer.SetValues("completer", result.Retorno);
            }
          },
          error => { }
        );
    } else if (AutocompleteCodigoIBGEComponent.cidadeIBGEList.length == 0) {
      setTimeout(() => { this.consultarCidades(descricao); }, 500);
    } else {
      if (this.completer != undefined) {
        this.completer.SetValues("completer", AutocompleteCodigoIBGEComponent.cidadeIBGEList);
      }
    }
  }

  /**
   *
   * @param item
   * @constructor
   */
  Selected(item: SelectedAutocompleteItem) {
    if (item == undefined || item.item == null) {
      return;
    }
    this.choosedCidadeIBGE.emit(item.item.original);
  }

  onKeypress(event) {
    if (event.target.value.length >= 3 && event.target.value.replace(/\s/g, "") != "") {
      this.consultarCidades(event.target.value);
    }
  }
}
