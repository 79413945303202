import { Veiculo } from "./veiculo";

export class DeclararOperacaoTransporte {
    frete: Frete;
    contratante: Consignatario;
    destinatario: Consignatario;
    consignatario: Consignatario;
    remetente: Consignatario;
    veiculos: Veiculo[];
    valores: Valores;
    pagamento: Pagamento;
    informacoesPagamento: InformacoesPagamento;
    ciotAjuste: string;
}

export class InformacoesPagamento {
    formaPagamento: number;
    dadosBancarios: DadosBancarios;
}

export class DadosBancarios {
    cpfCnpjConta: string; 
    bancoPagamento: string;
    agencia: string;
    conta: string;
    codigoBacen: string;
}

export class Consignatario {
    nomeRazaoSocial: string;
    cpfCnpj: string;
    nomeFantasia: string;
    endereco: Endereco;
    tipoPessoa: string;
    rntrc?: string;
}

export class Endereco {
    cep: string;
    codigoMunicipio: number;
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    telefone: string;
    email: string;
}

export class Frete {
    codigoMunicipioOrigem: number;
    codigoMunicipioDestino: number;
    dataInicioFrete: Date;
    dataTerminoFrete: Date;
    dadosComplementares: string;
    proprietario: Consignatario;
    motorista: Motorista;
    codigoNaturezaCarga: string;
    pesoCarga: number;
    tipoViagem: number;
    subContratacao: boolean;
    ciotPrincipal: string;
    cepOrigem: string;
    cepDestino: string;

    distanciaPercorrida: number;
    codigoTipoCarga: number;
    altodesempenho: boolean;
    destinacaoComercial: boolean;
    freteRetorno: FreteRetorno;
}

export class FreteRetorno {
    cepRetorno: string;
    distanciaRetorno: string;
}

export class Motorista {
    nome: string;
    cpfCnpj: string;
    numeroCNH: string;
}

export class Pagamento {
    formaPagmento: number;
    parcelas: Parcela[];
    parcelaUnica: boolean;
    infoPagamento: string;
    bancoPagamento: string;
    agenciaPagamento: string;
    contaPagamento: string;
}

export class Parcela {
    codigoParcela: string;
    valorParcela: number;
    vencimento: string;
}

export class Valores {
    valorFrete: number;
    valorCombustivel: number;
    valorDespesas: number;
    totalImposto: number;
    totalPegadio: number;
    quantidadeTarifas: number;
    valorTarifas: number;
}