import { Component, OnInit, ViewEncapsulation, AfterViewInit, ElementRef, ViewChildren, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName } from "@angular/forms";
import { Router } from "@angular/router";

import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

import { GenericValidator } from "../../../commom/generic.form.validator";
import { Pessoa } from "../../../ciot/models/pessoa";

import { PatternValidator } from '@angular/forms/src/directives/validators';
import { ContratanteComponent } from '../contratante/contratante.component';
import { DestinatarioComponent } from '../destinatario/destinatario.component';
import { ConsignatarioComponent } from '../consignatario/consignatario.component';
import { TransportadorVeiculosComponent } from '../transportador-veiculos/transportador-veiculos.component';
import { Cidade } from "../../models/cidade";
import { RetornoCidades } from "../../models/cidade";
import { AutoCompleteService } from '../../services/autocomplete.service';
import { NgAutocompleteComponent } from 'ng-auto-complete';
import { CiotService } from '../../services/ciot.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
    selector: 'app-remetente',
    templateUrl: './remetente.component.html',
    styles: []
})
export class RemetenteComponent implements OnInit, AfterViewInit {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    public errors: any[] = [];
    public remetenteForm: FormGroup;
    public veiculo: Pessoa;
    public displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;
    private title;
    public value: Date = new Date(2000, 2, 10);
    public cidade: RetornoCidades;
    maskCpfCnpj = '';

    // AutoComplete fields
    cidadeList: Array<Cidade> = [];
    noResult = false;
    cidadeCompleterText: string;

    @Input() contratanteComponent: ContratanteComponent;
    @Input() destinatarioComponent: DestinatarioComponent;
    @Input() consignatarioComponent: ConsignatarioComponent;
    @Input() transportadorVeiculosComponent: TransportadorVeiculosComponent;

    constructor(private fb: FormBuilder,
        private autoCompleteService: AutoCompleteService,
        private ciotService: CiotService,
        private router: Router) {

        this.ciotService.consultarCidades("")
            .subscribe(
                result => {
                    this.cidadeList = result;
                },
                error => { }
            );


        this.validationMessages = {
            cpfCnpj: {
                required: 'O CPF/CNPJ é obrigatório.'
            },
            nome: {
                required: 'O nome é obrigatório.'
            },
            cep: {
                required: 'O CEP é obrigatório.',
            },
            logradouro: {
                required: 'O logradouro é obrigatório.',
            },
            cidade: {
                required: 'A cidade é obrigatória.',
            },

        };

        this.genericValidator = new GenericValidator(this.validationMessages);
    }

    // AutoComplete métodos
    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
        this.cidade =  undefined;
    }

    onSelectCidade(event: TypeaheadMatch): void {
        this.cidade = event.item;
    }

    ngOnInit() {
        this.remetenteForm = this.fb.group({
            cpfCnpj: ['', [Validators.required]],
            nome: ['', [Validators.required]],
            cep: ['', [Validators.required]],
            logradouro: ['', [Validators.required]],
            cidade: ['', [Validators.required]],
            rntrc: ['',],
            nomeFantasia: ['',],
            numero: ['',],
            bairro: ['',],
            complemento: ['',],
            telefone: ['',],
            email: ['',]
            // documentoContratante: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(14)]],
        });

        this.remetenteForm.get('cpfCnpj').valueChanges.map(valor=>valor.length).subscribe(length=>{
            if(length>11){
                this.maskCpfCnpj = '00.000.000/0000-00'
              } else {
               this.maskCpfCnpj = '000.000.000-000'
              }
        
              if (length < 11 && this.maskCpfCnpj == '00.000.000/0000-00') {
               this.maskCpfCnpj = '000.000.000-000'
              }
          }) 
    }

    //Gambi do matias para desabilitar/habilitar o input
    // isVisible(resp): void {
    //     if(resp) { 
    //         $('#rntrc1').remove();
    //         $('#div1').append('<div id="rntrc1"><input class="form-control" id="rntrc" type="text" formControlName="rntrc" maxlength="9" RntrcMask disabled /></div>');
    //     } else {
    //         $('#rntrc1').remove();
    //         $('#div1').append('<div id="rntrc1"><input class="form-control" id="rntrc" type="text" formControlName="rntrc" maxlength="9" RntrcMask /> </div>');
    //     }
    // }

    ngAfterViewInit(): void {
        let controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

        Observable.merge(...controlBlurs).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.remetenteForm);
        });
    }

    ngFocus() {
        let inputField: HTMLElement = <HTMLElement>document.querySelectorAll('.dialog input')[0];
        inputField && inputField.focus();
    }

    limpar() {
        if (this.remetenteForm != undefined) {
            this.remetenteForm.reset();
            this.cidade =  undefined;
            this.cidadeCompleterText = "";
        }
    }

    copiarContratante() {
        if (this.contratanteComponent != undefined) {
            this.contratanteComponent.contratanteForm.value.cpfCnpj = this.contratanteComponent.CpfCnpjInteressado;
            this.remetenteForm.setValue(this.contratanteComponent.contratanteForm.value);
            this.cidade = this.contratanteComponent.cidade;
            this.cidadeCompleterText = this.contratanteComponent.cidade.NOME;
            this.remetenteForm.value.cpfCnpj = this.contratanteComponent.CpfCnpjInteressado;
            this.remetenteForm.markAsDirty();
        }
    }

    copiarDestinatario() {
        if (this.destinatarioComponent != undefined) {
            this.remetenteForm.setValue(this.destinatarioComponent.destinatarioForm.value);
            this.cidade = this.destinatarioComponent.cidade;
            this.cidadeCompleterText = this.destinatarioComponent.cidade.NOME;
            this.remetenteForm.markAsDirty();
        }
    }

    copiarConsignatario() {
        if (this.consignatarioComponent != undefined) {
            this.remetenteForm.setValue(this.consignatarioComponent.consignatarioForm.value);
            this.cidade = this.consignatarioComponent.cidade;
            this.cidadeCompleterText = this.consignatarioComponent.cidade.NOME;
            this.remetenteForm.markAsDirty();
        }
    }

    copiarTransportador() {
        if (this.transportadorVeiculosComponent != undefined && this.transportadorVeiculosComponent.transportadorForm.dirty) {
            this.transportadorVeiculosComponent.transportadorForm.value.cpfCnpj = this.transportadorVeiculosComponent.CpfCnpjTransportador;
            this.transportadorVeiculosComponent.transportadorForm.value.nome = this.transportadorVeiculosComponent.NomeRazaoSocialTransportador;
            this.transportadorVeiculosComponent.transportadorForm.value.nomeFantasia = this.transportadorVeiculosComponent.NomeRazaoSocialTransportador;
            this.transportadorVeiculosComponent.transportadorForm.value.rntrc = this.transportadorVeiculosComponent.RNTRCTransportador;
            this.remetenteForm.setValue(this.transportadorVeiculosComponent.transportadorForm.value);
            this.cidade = this.transportadorVeiculosComponent.cidade;
            this.cidadeCompleterText = this.transportadorVeiculosComponent.cidade.NOME;
            this.remetenteForm.markAsDirty();
        }
    }

    onError(serviceReturn) {
        this.errors = Object.assign([], serviceReturn.error.errors);
    }

    onSaveComplete(): void {
        this.remetenteForm.reset();
    }
}
