import { Excecao } from "../common/excecao";

export class ConsultaSituacaoTransportadorResponse {
    Sucesso: boolean;
    Mensagem: string;
    Retorno: DadosSituacaoTransportador;
}

class DadosSituacaoTransportador {
    CpfCnpjTransportador: string;
    RNTRCTransportador: string;
    NomeRazaoSocialTransportador: string;
    RNTRCAtivo: boolean;
    DataValidadeRNTRC?: any;
    TipoTransportador: string;
    EquiparadoTAC: boolean;
    Excecao: Excecao;
    Sucesso: boolean;
}

export class ConsultaSituacaoTransportadorRequest {
    CpfCnpjInteressado: string;
    CpfCnpjTransportador: string;
    RNTRCTransportador: string;
}