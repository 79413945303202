import {AfterViewInit, Component, ElementRef, OnInit, ViewChildren, ViewContainerRef, Input} from '@angular/core';
import {FormBuilder, FormControlName, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";

import {Observable} from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge'; 
 
import {GenericValidator} from "../../commom/generic.form.validator";
import {Ciot} from "../models/ciot"
import {CiotService} from "../services/ciot.service";
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import {Subscription} from 'rxjs/Subscription';
import {DatePipe} from '@angular/common';
import {ConsultarSituacaoCiotRequest} from '../models/consulta-ciot/consultar-situacao-ciot-request';
import {ConsultarSituacaoCiotDTO} from '../models/consulta-ciot/consultar-situacao-ciot-dto';
import {CiotResponse} from '../models/consulta-ciot/consulta-ciot-response';
import {ToastsManager} from 'ng2-toastr';
import {Mensagens, TipoViagem} from '../util/enums';
import {ObjImprimir} from '../models/objImprimir';

@Component({
  selector: 'app-consulta-ciot',
  templateUrl: './consulta-ciot.component.html',
  styles: []
})
export class ConsultaCiotComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  ciotId: string;
  senha: string;
  cpfMotorista: string;
  cpfContratante: string;
  cpfDestinatario: string;
  cepOrigem: string;
  cepDestino: string;

  public bsConfig: Partial<BsDatepickerConfig>;
  public errors: any[] = [];
  public consultaCiotForm: FormGroup;
  public ciot: Ciot;
  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  private title;
  public sub: Subscription;
  public value: Date = new Date(2000, 2, 10);
  private consultaDTO: ConsultarSituacaoCiotDTO = new ConsultarSituacaoCiotDTO();
  public objImprimir: ObjImprimir = new ObjImprimir();
  private fromDeclaracao: boolean = false;

  public botaoImprimirHabilitado : boolean = true;

  constructor(private fb: FormBuilder,
    private service: CiotService,
    vRef: ViewContainerRef,
    private toastr: ToastsManager,
    private router: Router,
    private route: ActivatedRoute) {

    this.validationMessages = {
      dtInicioFrete: {
        required: 'A data início do frete é obrigatório.'
      },
      dtTerminoFrete: {
        required: 'A data término do frete é obrigatório.'
      },
      cidadeOrigem: {
        required: 'A cidade origem do contratante é obrigatório.'
      },
      cidadeDestino: {
        required: 'A cidade destino é obrigatório.',
      },
      naturezaCarga: {
        required: 'A natureza da carga é obrigatório.',
      },
      valorFrete: {
        required: 'O valor do frete é obrigatório.',
      },
      quantidadeTarifas: {
        required: 'A quantidade de tarifas é obrigatório.',
      },
      valorTotalTarifas: {
        required: 'O valor total das tarifas é obrigatório.',
      },
      rntrc: {
        required: '',
      }
    };

    this.genericValidator = new GenericValidator(this.validationMessages);

  }

  consultarCiot(ciotId, senha) {
    let a = new ConsultarSituacaoCiotRequest();
    a.ciot = ciotId;
    a.senhaAlteracao = senha;
    this.service.consultarCiot(a)
      .subscribe(
        response => this.preencherForm(response),
        error => { this.onError(error) }
      );
  }

  preencherForm(consulta: CiotResponse): void {
    this.consultaCiotForm.markAsDirty;
    if (consulta.Retorno.Sucesso) {
      this.consultaDTO.ciot = consulta.Retorno.CIOT;
      this.consultaDTO.contratado = consulta.Retorno.NomeProprietario; 
      this.consultaDTO.dtInicioFrete = new DatePipe('en-US').transform(String(consulta.Retorno.DataInicioFrete).substr(0,10), "dd/MM/yyyy");
      this.consultaDTO.dtTerminoFrete = new DatePipe('en-US').transform(String(consulta.Retorno.DataTerminoFrete).substr(0,10), "dd/MM/yyyy");
      this.consultaDTO.rntrc = consulta.Retorno.RntrcProprietario;
      this.consultaDTO.senha = this.senha;
      this.consultaDTO.tipoViagem = Number(TipoViagem.Padrao) == consulta.Retorno.TipoViagem ? Mensagens.PADRAO : Mensagens.TAC_AGREGADO;
      this.objImprimir.aTipoViagem = consulta.Retorno.TipoViagem;
      this.objImprimir.aCpfCnpjProprietario = consulta.Retorno.CpfCnpjProprietario;
      this.consultaDTO.totalImposto = consulta.Retorno.TotalImposto;
      this.consultaDTO.totalPedagio = consulta.Retorno.TotalPegadio;
      this.consultaDTO.valorFrete = consulta.Retorno.ValorFrete;
      this.consultaDTO.encerrada = consulta.Retorno.Encerrado ? "Sim" : "Não";
      //this.consultaDTO.aviso = consulta.Retorno.AvisoTransportador;
      this.consultaDTO.situacaoDeclaracao = consulta.Retorno.Situacao;

      this.consultaCiotForm.setValue(this.consultaDTO);
    } else {
      this.router.navigate(['/']).then(() => this.toastr.error(Mensagens.CIOT_NAO_ENCONTRADO_CREDENCIAIS_INFORMADAS, Mensagens.OOPS));
    }
  }

  ngOnInit() {
    this.consultaCiotForm = this.fb.group({
      ciot: ['',],

      rntrc: ['',],
      contratado: ['',],
      dtInicioFrete: ['',],

      dtTerminoFrete: ['',],
      valorFrete: ['',],
      totalImposto: ['',],
      totalPedagio: ['',],
      tipoViagem: ['',],
      senha: ['',],
      situacaoDeclaracao: ['',],
      encerrada: ['',], 

      //aviso: ['',],
      // documentoContratante: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(14)]],
    });
    this.ciot = new Ciot();

    this.sub = this.route.params.subscribe(
      params => {
        this.ciotId = params['ciot'];
        this.senha = params['senha'];
        this.cpfMotorista = params['cpfMotorista'];
        this.cpfContratante = params['cpfContratante'];
        this.cepOrigem = params['cepOrigem'];
        this.cepDestino = params['cepDestino'];
        this.fromDeclaracao = params['fromDeclaracao'] == "true"; 
        this.consultarCiot(this.ciotId, this.senha);
      });

      if (this.fromDeclaracao) {
        this.toastr.success('CIOT declarado com sucesso!', Mensagens.SUCESSO);
      }
  }

  ngAfterViewInit(): void {
    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    Observable.merge(...controlBlurs).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.consultaCiotForm);
    });
  }

  voltar() {
    this.router.navigateByUrl('/', { skipLocationChange: true });
  }

  imprimir() {
    this.objImprimir.aDataInicio = this.consultaDTO.dtInicioFrete;
    this.objImprimir.aDataTermino = this.consultaDTO.dtTerminoFrete;
    this.objImprimir.aTipoViagem = this.objImprimir.aTipoViagem;
    this.objImprimir.aCpfCnpjProprietario = this.objImprimir.aCpfCnpjProprietario;
    this.objImprimir.aCpfCnpjContratante = this.cpfContratante;
    this.objImprimir.aStatus = Number(this.consultaDTO.situacaoDeclaracao);
    this.objImprimir.aCpfCnpjCliente = "09313766000109"; //No momento esta fixo, pois n tem a opcao de selecionar o cliente na tela
    this.objImprimir.aCpfCnpjMotorista = this.cpfMotorista;
    this.objImprimir.cepOrigem = this.cepOrigem;
    this.objImprimir.cepDestino = this.cepDestino;  

    //this.service.gestaoCiot(this.objImprimir) 

    var url = CiotService.UrlBase +"Operacoes/GestaoCiot/";
    this.openWindowWithPost(url, this.objImprimir); 

    this.timeoutBotao();
  }

  openWindowWithPost(url, data) {
    var form = document.createElement("form");
    form.target = "_blank";
    form.method = "POST";
    form.action = url;
    form.style.display = "none";
    form.data = data;

    // for (var key in data) {
    //     var input = document.createElement("input");
    //     input.type = "hidden";
    //     input.name = key;
    //     input.value = data[key];
    //     form.appendChild(input);
    // }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
}

timeoutBotao() {
    this.botaoImprimirHabilitado = false;
    setTimeout(() => {
      this.botaoImprimirHabilitado = true;
    }, 3000);
}

  onError(serviceReturn) {
    this.errors = Object.assign([], serviceReturn.error.errors);
  }

  onSaveComplete(): void {
    this.consultaCiotForm.reset();
    this.errors = [];
  }


}
