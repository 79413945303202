import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: [],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent {
}
