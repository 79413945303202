import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[PlacaAutomovel]'
})
export class PlacaAutomovel {
    // regexStr = '^[0-9]*$';
    backSpace = 'Backspace';
    delete = 'Delete';
    arrowLeft = 'ArrowLeft';
    arrowRight = 'ArrowRight';
    tab = 'Tab';
    v = 'v';
    vUpper = 'V';
    a = 'a';
    aUpper = 'A';
    x = 'x';
    xUpper = 'X';
    z = 'z';
    zUpper = 'Z';
    c = 'c';
    cUpper = 'C';

    @Input() isAlphaNumeric: boolean;

    constructor(private el: ElementRef) {
    }


    @HostListener('keypress', ['$event']) onKeyPress(event) {
        var k = event.charCode;
        if ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)) {
            return true;
        } else if (new RegExp(this.backSpace).test(event.key)) {
            return true;
        } else if (new RegExp(this.delete).test(event.key)) {
            return true;
        } else if (new RegExp(this.arrowLeft).test(event.key)) {
            return true;
        } else if (new RegExp(this.arrowRight).test(event.key)) {
            return true;
        } else if (new RegExp(this.tab).test(event.key)) {
            return true;
        } else if (new RegExp(this.v).test(event.key) && event.ctrlKey) {
            return true;
        } else if (new RegExp(this.vUpper).test(event.key) && event.ctrlKey) {
            return true;
        } else if (new RegExp(this.a).test(event.key) && event.ctrlKey) {
            return true;
        } else if (new RegExp(this.aUpper).test(event.key) && event.ctrlKey) {
            return true;
        } else if (new RegExp(this.x).test(event.key) && event.ctrlKey) {
            return true;
        } else if (new RegExp(this.xUpper).test(event.key) && event.ctrlKey) {
            return true;
        } else if (new RegExp(this.z).test(event.key) && event.ctrlKey) {
            return true;
        } else if (new RegExp(this.zUpper).test(event.key) && event.ctrlKey) {
            return true;
        } else if (new RegExp(this.c).test(event.key) && event.ctrlKey) {
            return true;
        } else if (new RegExp(this.cUpper).test(event.key) && event.ctrlKey) {
            return true;
        }
        return false;
    }

    // @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    //   this.validateFields(event);
    // }

    validateFields(event) {
        setTimeout(() => {

            this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z ]/g, '').replace(/\s/g, '');
            event.preventDefault();

        }, 100)
    }
}