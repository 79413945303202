import { Veiculo } from "./veiculo";

export class Ciot {
    ciot: string;
    rntrc: string;
    contratado: string;
    dtInicioFrete: Date;
    dtTerminoFrete: Date;
    valorFrete: number;
    valorFretePago: number;
    totalImposto: number;
    totalPedagio: number;
    tipoViagem: number;
    senha: string;
    encerrada: boolean;
    aviso: string;
    pesoCarga: number;
    quantidadeTarifas: number;
    valorTotalTarifas: number;
    valorDespesas: number;
}

export class CiotRetificacao {
    ciot: Ciot;
    veiculos: Array<Veiculo>
}