// https://github.com/sengirab/ngAutocomplete

import { Component, ViewChild, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { CreateNewAutocompleteGroup, SelectedAutocompleteItem, NgAutocompleteComponent } from "ng-auto-complete";
import { CiotService } from "../../ciot/services/ciot.service";
import { ArrayObservable } from "rxjs/observable/ArrayObservable";
import { AutoCompleteService } from "../../ciot/services/autocomplete.service";
import { Estado } from "../../ciot/models/estado";

@Component({
  selector: 'app-autocomplete-estado',
  templateUrl: './autocomplete-estado.component.html',
  styles: [],
})
export class AutocompleteEstadoComponent implements OnInit {

  @ViewChild(NgAutocompleteComponent) public completer: NgAutocompleteComponent;
  static estadoList: Array<Estado> = [];
  static inicializado: boolean = false;
  @Output() choosedEstado = new EventEmitter();

  public group = [
    CreateNewAutocompleteGroup(
      "Selecione",
      'completer',
      AutocompleteEstadoComponent.estadoList,
      { titleKey: 'nome', childrenKey: null }
    ),
  ];

  constructor(private service: CiotService, private autocompleteService: AutoCompleteService) {
    this.consultarEstado();
  }

  ngOnInit(): void {
    this.autocompleteService.recebeMensagem().subscribe(mensagem => {
      // chame sua funcao qualquer no componente
    }, (error) => {
    });
    this.autocompleteService.recebeResetInput().subscribe(component => {
      this.completer.ResetInputs();
    },
      (error) => {
      });
  }

  consultarEstado(): void {
    if (!AutocompleteEstadoComponent.inicializado) {
      AutocompleteEstadoComponent.inicializado = true;
      this.service.consultarEstado()
        .subscribe(
          result => {
            AutocompleteEstadoComponent.estadoList = result;
            if (this.completer != undefined) {
              this.completer.SetValues("completer", result);
            }
          },
          error => { }
        );
    } else if (AutocompleteEstadoComponent.estadoList.length == 0) {
      setTimeout(() => { this.consultarEstado(); }, 500);
    } else {
      if (this.completer != undefined) {
        this.completer.SetValues("completer", AutocompleteEstadoComponent.estadoList);
      }
    }
  }


  /**
   *
   * @param item
   * @constructor
   */
  Selected(item: SelectedAutocompleteItem) {
    if (item == undefined || item.item == null) return;
    
    this.choosedEstado.emit(item.item.original);
  }
}
