export class ObjImprimir {
    aDataInicio: string;
    aDataTermino: string;  
    aCpfCnpjCliente: string;
    aCpfCnpjContratante: string;
    aCpfCnpjProprietario: string;
    aCpfCnpjMotorista: string;
    aTipoViagem: number;
    aStatus: number;
    cepOrigem: string;
    cepDestino: string;
}