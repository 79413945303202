import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChildren} from '@angular/core';
import {FormBuilder, FormControlName, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

import {Observable} from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

import {GenericValidator} from "../../../commom/generic.form.validator";
import {Veiculo} from "../../../ciot/models/veiculo"
import {Cidade} from "../../models/cidade";
import {RetornoCidades} from "../../models/cidade";
import {AutoCompleteService} from '../../services/autocomplete.service';
import {ToastsManager} from 'ng2-toastr';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {CiotService} from '../../services/ciot.service';
import {Mensagens} from '../../util/enums';

@Component({
    selector: 'app-transportador-veiculos',
    templateUrl: './transportador-veiculos.component.html',
    styles: []
})
export class TransportadorVeiculosComponent implements OnInit, AfterViewInit {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    public errors: any[] = [];
    public transportadorForm: FormGroup;
    public veiculoForm: FormGroup;
    public veiculo: Veiculo;
    public displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;
    private title;
    public value: Date = new Date(2000, 2, 10);
    public veiculoList: Array<Veiculo> = [];
    public cidade: RetornoCidades;

    // AutoComplete fields
    cidadeList: Array<Cidade> = [];
    noResult = false;
    cidadeCompleterText: string;

    @Input() public CpfCnpjTransportador: string;
    @Input() public RNTRCTransportador: string;
    @Input() public NomeRazaoSocialTransportador: string;

    constructor(private fb: FormBuilder,
        private ciotService: CiotService,
        private autoCompleteService: AutoCompleteService,
        private router: Router,
        private toastr: ToastsManager) {

        this.ciotService.consultarCidades("")
            .subscribe(
                result => {
                    this.cidadeList = result;
                },
                error => { }
            );

        this.validationMessages = {
            cpfCnpj: {
                required: 'O CPF/CNPJ é obrigatório.'
            },
            rntrc: {
                required: 'O RNTRC é obrigatório.'
            },
            nome: {
                required: 'O nome é obrigatório.'
            },
            nomeFantasia: {
                required: 'A cidade destino é obrigatório.',
            },
            cep: {
                required: 'O CEP é obrigatório.',
            },
            logradouro: {
                required: 'O logradouro é obrigatório.',
            },
            numero: {
                required: 'O número é obrigatório.',
            },
            cidade: {
                required: 'A Cidade é obrigatória.',
            },
            bairro: {
                required: 'O bairro é obrigatório.',
            },
            complemento: {
                required: 'O complemento é obrigatório.',
            },
            telefone: {
                required: 'O telefone é obrigatório.',
            },
            email: {
                required: 'O email é obrigatório.',
            },
            placa: {
                required: 'A placa do veículo é obrigatória.',
            }
        };

        this.genericValidator = new GenericValidator(this.validationMessages);
    }

    // AutoComplete métodos
    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
        this.cidade = undefined;
    }

    onSelectCidade(event: TypeaheadMatch): void {
        this.cidade = event.item;
    }

    ngOnInit() {
        this.transportadorForm = this.fb.group({
            cpfCnpj: ['', [Validators.required]],
            rntrc: ['', [Validators.required]],
            nome: ['', [Validators.required]],
            nomeFantasia: ['', [Validators.required]],
            cep: ['', [Validators.required]],
            logradouro: ['', [Validators.required]],
            numero: ['', [Validators.required]],
            cidade: ['', [Validators.required]],
            bairro: ['', [Validators.required]],
            complemento: ['', [Validators.required]],
            telefone: ['', [Validators.required]],
            email: ['',]
            // documentoContratante: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(14)]],
        });
        this.veiculoForm = this.fb.group({
            placa: ['', Validators.required],
            rntrc: ['', Validators.required],
            // documentoContratante: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(14)]],
        });

        this.transportadorForm.controls['nome'].setValue(this.NomeRazaoSocialTransportador);
        this.transportadorForm.controls['rntrc'].setValue(this.RNTRCTransportador);
        this.transportadorForm.controls['cpfCnpj'].setValue(this.CpfCnpjTransportador);
        this.transportadorForm.controls['nomeFantasia'].setValue(this.NomeRazaoSocialTransportador);
        this.transportadorForm.value.nome = this.NomeRazaoSocialTransportador;
        this.transportadorForm.value.rntrc = this.RNTRCTransportador;
        this.transportadorForm.value.cpfCnpj = this.CpfCnpjTransportador;
        this.transportadorForm.value.nomeFantasia = this.NomeRazaoSocialTransportador;

        this.transportadorForm.controls['nome'].disable();
        this.transportadorForm.controls['rntrc'].disable();
        this.transportadorForm.controls['cpfCnpj'].disable();
        this.transportadorForm.controls['nomeFantasia'].disable();
    }

    ngAfterViewInit(): void {
        let controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

        this.veiculoForm.setParent(this.transportadorForm);
        Observable.merge(...controlBlurs).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.transportadorForm);
        });
    }

    adicionarVeiculo() {
        if (this.veiculoForm.dirty && this.veiculoForm.valid) {
            
            this.veiculoForm.value.rntrc = this.veiculoForm.value.rntrc.length == 9 && this.veiculoForm.value.rntrc.substring(1,0) == "0" ? this.veiculoForm.value.rntrc.substring(9,1) : this.veiculoForm.value.rntrc;
            this.veiculo = this.veiculoForm.value;
            this.veiculo.placa = this.veiculo.placa.toUpperCase().replace('-','');

            if (this.validarVeiculo(this.veiculo)) return;

            this.veiculoList.push(this.veiculo);
            this.veiculoForm.reset();
            this.ngFocus();
        }
    }

    validarVeiculo(veiculo: Veiculo): boolean {
        var regexp = new RegExp(/[a-zA-Z]{3}-?\d{4}/),
            test = regexp.test(veiculo.placa);
        if (!test || veiculo.placa.length > 8) {
            this.toastr.error("A placa informada é inválida.", Mensagens.OOPS);
            return true;
        }

        for (let index = 0; index < this.veiculoList.length; index++) {
            if (this.veiculoList[index].placa == veiculo.placa) {
                this.toastr.error("Este veículo já foi adicionado", "Oops!");
                return true;
            }
        }
    }

    // omitSpecialChar(event) {
    //     var k;
    //     k = event.charCode;
    //     return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    // }

    removerVeiculo(veiculo) {
        this.veiculoList
        for (var i = 0; i < this.veiculoList.length; i++) {
            if (this.veiculoList[i].placa == veiculo.placa) {
                var index = this.veiculoList.indexOf((this.veiculoList[i]));
                this.veiculoList.splice(index, 1)
            }
        }
    }

    ngFocus() {
        let inputField: HTMLElement = <HTMLElement>document.querySelectorAll('.dialog input')[0];
        inputField && inputField.focus();
    }

    onError(serviceReturn) {
        this.errors = Object.assign([], serviceReturn.error.errors);
    }

    onSaveComplete(): void {
        this.transportadorForm.reset();
    }
}
