// Versão para ambiente do desenvolvedor Localhost
// ng serve => Hospedar server localhost para testar
// ng build => Gerar versão com este arquivo de configuração

export const environment = {
  production: false,
  // url: "http://hml-mhservices.sistemainfo.com.br"
   //url: "http://sw12-140:1000"
  // url: "http://hml-mhservices.sistemainfo.com.br"
    // url: "http://hmlciotpws.bancobbcdigital.com.br:8091/"
   //url: "http://10.151.0.31:1982/"
   //url: "http://localhost:65413/"
   url: "http://ciotpws.bancobbcdigital.com.br:8091"
}

