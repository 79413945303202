import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[SpecialCharacterNotAllowed]'
})
export class SpecialCharacterNotAllowed {
  regexStr = '^[a-zA-Z0-9@._]*$';

  backSpace = 'Backspace';
  delete = 'Delete';
  arrowLeft = 'ArrowLeft';
  arrowRight = 'ArrowRight';
  tab = 'Tab';
  v = 'v';
  vUpper = 'V';
  a = 'a';
  aUpper = 'A';
  x = 'x';
  xUpper = 'X';
  z = 'z';
  zUpper = 'Z';
  c = 'c';
  cUpper = 'C';
  @Input() isAlphaNumeric: boolean;

  constructor(private el: ElementRef) { }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    if (new RegExp(this.regexStr).test(event.key)) {
      return true;
    } else if (new RegExp(this.backSpace).test(event.key)) {
      return true;
    } else if (new RegExp(this.delete).test(event.key)) {
      return true;
    } else if (new RegExp(this.arrowLeft).test(event.key)) {
      return true;
    } else if (new RegExp(this.arrowRight).test(event.key)) {
      return true;
    } else if (new RegExp(this.tab).test(event.key)) {
      return true;
    } else if (new RegExp(this.v).test(event.key) && event.ctrlKey) {
      return true;
    } else if (new RegExp(this.vUpper).test(event.key) && event.ctrlKey) {
      return true;
    } else if (new RegExp(this.a).test(event.key) && event.ctrlKey) {
      return true;
    } else if (new RegExp(this.aUpper).test(event.key) && event.ctrlKey) {
      return true;
    } else if (new RegExp(this.x).test(event.key) && event.ctrlKey) {
      return true;
    } else if (new RegExp(this.xUpper).test(event.key) && event.ctrlKey) {
      return true;
    } else if (new RegExp(this.z).test(event.key) && event.ctrlKey) {
      return true;
    } else if (new RegExp(this.zUpper).test(event.key) && event.ctrlKey) {
      return true;
    } else if (new RegExp(this.c).test(event.key) && event.ctrlKey) {
      return true;
    } else if (new RegExp(this.cUpper).test(event.key) && event.ctrlKey) {
      return true;
    }
    return false;
  }

  // @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    // alert("c");
    // this.validateFields(event);
  // }

  validateFields(event) {
    setTimeout(() => {
      alert(this.el.nativeElement.value);
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z ]/g, '').replace(/\s/g, '');
      event.preventDefault();

    }, 1)
  }
}