import { AfterViewInit, Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, listLocales } from 'ngx-bootstrap/chronos'
import { ptBrLocale } from 'ngx-bootstrap/locale';


import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

import { GenericValidator } from "../../../commom/generic.form.validator";

import { Cidade } from '../../models/cidade';
import { NaturezaCarga } from '../../models/natureza-carga';
import { RetornoNatureza } from '../../models/natureza-carga';
import { Estado } from '../../models/estado';
import { Tipos } from '../../models/tipoCarga';
import { TipoCarga } from '../../models/tipoCarga';
import { AutoCompleteService } from '../../services/autocomplete.service';
import { CiotTabsService } from '../../services/ciot-tabs.service';
import { TipoViagem } from '../../util/enums';
import { CiotService } from '../../services/ciot.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ContratanteComponent } from '../contratante/contratante.component'
import { RemetenteComponent } from '../remetente/remetente.component'
import { CodigoIBGE } from '../../models/codigoIBGE';
import { Cidades } from '../../models/codigoIBGE';

@Component({
    selector: 'app-viagem',
    templateUrl: './viagem.component.html',
})
export class ViagemComponent implements OnInit, AfterViewInit {

    locale = ptBrLocale;
    locales = listLocales();

    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
    bsConfig: Partial<BsDatepickerConfig>;

    public errors: any[] = [];
    public viagemForm: FormGroup;
    public displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;
    private title;
    public value: Date = new Date(2000, 2, 10);
    public naturezaCarga: NaturezaCarga;
    public naturezaCargaSelect: RetornoNatureza;
    public tipoCarga: Tipos;
    public codigoIBGESelect: Cidades;
    public cidadeIBGE: CodigoIBGE;
    estado: Estado = new Estado();
    public tipoViagem: string = TipoViagem.Padrao;

    // AutoComplete fields
    cidadeList: Array<Cidade> = [];
    naturezaCargaList: Array<NaturezaCarga> = [];
    tipoCargaList: Array<Tipos> = [];
    cidadeIBGEList: Array<CodigoIBGE> = [];

    noResultOrigem = false;
    noResultDestino = false;
    noResultNatureza = false;
    noResultCodigoIBGE = false;

    // cidadeOrigemCompleterText: string;
    // cidadeDestinoCompleterText: string;
    naturezaCompleterText: string;
    codigoIBGECompleterText: string;

    dataInicio = new Date();
    dataFim = new Date();

    constructor(private fb: FormBuilder,
        private autoCompleteService: AutoCompleteService,
        private ciotTabsService: CiotTabsService,
        private contratenteComp: ContratanteComponent,
        private remetenteComp: RemetenteComponent,
        private ciotService: CiotService,
        private router: Router,
        private localeService: BsLocaleService) {
        defineLocale("ptbr", ptBrLocale)

        this.bsConfig = Object.assign({}, { containerClass: "theme-red" });
        this.localeService.use("ptbr");

        this.ciotService.consultarNaturezaCarga("")
            .subscribe(
                result => {
                    this.naturezaCargaList = result;
                },
                error => {
                }
            );

        this.ciotService.consultarTiposCarga("")
            .subscribe(
                result => {
                    this.tipoCargaList = result.Retorno;
                },
                error => {
                }
            );


        this.validationMessages = {
            dtInicioFrete: {
                required: 'A data início do frete é obrigatório.'
            },
            dtTerminoFrete: {
                required: 'A data término do frete é obrigatório.'
            },
            naturezaCarga: {
                required: 'A natureza da carga é obrigatório.',
            },
            valorFrete: {
                required: 'O valor do frete é obrigatório.',
            },
            quantidadeTarifas: {
                required: 'A quantidade de tarifas é obrigatório.',
            },
            valorTotalTarifas: {
                required: 'O valor total das tarifas é obrigatório.',
            },
            pesoCarga: {
                required: 'O peso da carga é obrigatório.'
            },
            tipoCarga: {
                required: 'O tipo da carga é obrigatório.'
            },
            cidadeIBGE: {
                required: 'O código IBGE da cidade é obrigatório.'
            },
        };

        this.genericValidator = new GenericValidator(this.validationMessages);
    }

    typeaheadNoResultsNatureza(event: boolean): void {
        this.noResultNatureza = event;
        this.naturezaCarga = undefined;
    }

    typeaheadNoResultsCodigoIBGE(event: boolean): void {
        this.noResultCodigoIBGE = event;
        this.cidadeIBGE = undefined;
    }

    onSelectNatureza(event: TypeaheadMatch): void {
        this.naturezaCargaSelect = event.item;
    }

    onChoosedTipoCarga(evento) {
        this.tipoCarga = evento;
    }

    onChoosedCidadeByIBGE(evento: TypeaheadMatch): void {
        this.codigoIBGESelect = evento.item;
    }

    ngOnInit() {
        this.viagemForm = this.fb.group({
            dtInicioFrete: [[Validators.required]],
            dtTerminoFrete: ['', Validators.required],
            naturezaCarga: ['',],
            tipoCarga: ['', [Validators.required]],
            cidadeIBGE: ['', [Validators.required]],
            valorTotalTarifas: ['',],
            pesoCarga: ['', Validators.required],
            valorDespesas: ['',],
            totalImposto: ['',],
            valorCombustivel: ['',],
            totalPedagio: ['',],
            valorFrete: ['', Validators.required],
            quantidadeTarifas: ['', Validators.required],
            autoDesempenho: [, []],
            freteRetorno: [, []],
            cepRetorno: ['',],
            distanciaRetorno: ['',],
            distanciaPercorrida: ['',],
            destinacaoComercial: [{ id: 2, name: 'destinacaoComercial', check: true },],
            // documentoContratante: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(14)]],
        });
        this.viagemForm.controls['dtInicioFrete'].setValue(this.currentDate());
        this.viagemForm.controls['dtTerminoFrete'].setValue(this.currentDate());

        document.getElementById('idCepRetorno').hidden = true;
        document.getElementById('idDistanciaRetorno').hidden = true;
        this.onChanges();
    }

    onChanges() {
        this.viagemForm.get("freteRetorno").valueChanges.subscribe(value => {
            if (value) {
                document.getElementById('idCepRetorno').hidden = false;
                document.getElementById('idDistanciaRetorno').hidden = false;
            } else {
                document.getElementById('idCepRetorno').hidden = true;
                document.getElementById('idDistanciaRetorno').hidden = true;
            }
        });

        this.viagemForm.get("autoDesempenho").valueChanges.subscribe(value => {
            this.viagemForm.controls['autoDesempenho'].setValue = value;
        });
    }

    currentDate() {
        const currentDate = new Date();
        return currentDate.toISOString().substring(0, 10);
    }

    ngAfterViewInit(): void {
        let controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

        Observable.merge(...controlBlurs).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.viagemForm);
        });
    }

    onTipoViagemChanged() {
        let show = TipoViagem.Padrao == this.tipoViagem;
        if (show) {
            this.viagemForm.controls['dtInicioFrete'].enable();
            this.viagemForm.controls['dtInicioFrete'].setValue(this.currentDate());
            this.dataInicio = new Date();
            this.viagemForm.controls['pesoCarga'].enable();
            document.getElementById('idNaturezaCarga').hidden = false;
            document.getElementById('idPesoCarga').hidden = false;
        } else {
            this.dataInicio = new Date();
            this.viagemForm.controls['dtInicioFrete'].disable();
            this.viagemForm.controls['pesoCarga'].disable();
            this.viagemForm.controls['dtInicioFrete'].reset();
            this.viagemForm.controls['pesoCarga'].reset();
            this.viagemForm.controls['cepOrigem'].reset();
            this.viagemForm.controls['cepDestino'].reset();
            this.noResultDestino = false;
            this.noResultOrigem = false;
            this.noResultNatureza = false;
            this.naturezaCompleterText = null;
            this.codigoIBGECompleterText = null;
            document.getElementById('idNaturezaCarga').hidden = true;
            document.getElementById('idPesoCarga').hidden = true;
        }
        this.ciotTabsService.setVisible(show);
        this.contratenteComp.isVisible(show);
    }

    onError(serviceReturn) {
        this.errors = Object.assign([], serviceReturn.error.errors);
    }

    onSaveComplete(): void {
        this.viagemForm.reset();
    }
}
