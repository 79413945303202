// https://github.com/sengirab/ngAutocomplete

import { Component, ViewChild, Output, EventEmitter } from "@angular/core";
import { CreateNewAutocompleteGroup, SelectedAutocompleteItem, NgAutocompleteComponent } from "ng-auto-complete";
import { CiotService } from "../../ciot/services/ciot.service";
import { ArrayObservable } from "rxjs/observable/ArrayObservable";
import { Banco } from "../../ciot/models/banco"; 
import { DadosRetornoBanco } from "../../ciot/models/banco";
import { ObjBancos } from "./objBancos";

@Component({
  selector: 'app-autocomplete-bancos',
  templateUrl: './autocomplete-bancos.component.html',
  styles: []
})

export class AutocompleteBancosComponent {

  @ViewChild(NgAutocompleteComponent) public completer: NgAutocompleteComponent;
  static bancoList: Array<DadosRetornoBanco> = [];
  static inicializado: boolean = false;
  static arrayBancos: Array<ObjBancos> = [];

  @Output() choosedBanco = new EventEmitter();

  public group = [
    CreateNewAutocompleteGroup(
      "Selecione",
      'completer',
      AutocompleteBancosComponent.arrayBancos,
      { titleKey: 'nome', childrenKey: null }
    ),
  ];

  constructor(private service: CiotService) {
    this.consultarBancos();
  }

  consultarBancos(): void {
    if (!AutocompleteBancosComponent.inicializado) {
      AutocompleteBancosComponent.inicializado = true;
      this.service.consultarBancos()
        .subscribe(
          result => {
            AutocompleteBancosComponent.bancoList = result.Retorno;

            AutocompleteBancosComponent.bancoList.forEach(item => {
              var obj = new ObjBancos();
              obj.nome = item.NOME;
              obj.id = item.CODIGO;

              AutocompleteBancosComponent.arrayBancos.push(obj);
            });

            if (this.completer != undefined) {
              this.completer.SetValues("completer", AutocompleteBancosComponent.arrayBancos);
            }
          },
          error => { }
        );
    } else if (AutocompleteBancosComponent.arrayBancos.length == 0) {
      setTimeout(() => { this.consultarBancos(); }, 500);
    } else {
      if (this.completer != undefined) {

            AutocompleteBancosComponent.bancoList.forEach(item => {
              var obj = new ObjBancos();
              obj.nome = item.NOME;
              obj.id = item.CODIGO;

              AutocompleteBancosComponent.arrayBancos.push(obj);
            });

        this.completer.SetValues("completer", AutocompleteBancosComponent.arrayBancos);
      }
    }
  }

  /**
   *
   * @param item
   * @constructor
   */
  Selected(item: SelectedAutocompleteItem) {
    if (item == undefined || item.item == null) return;
    this.choosedBanco.emit(item.item.original);
  }
}
