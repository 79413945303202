import { Ciot } from "./ciot";
import { Cidade } from "./cidade";
import { NaturezaCarga } from "./natureza-carga";

export class Viagem {
    cidadeOrigem: Cidade;
    cidadeDestino: Cidade;
    naturezaCarga: NaturezaCarga;
    pesoCarga: number;
    qtdViagens: number;
}

export class ViagemEncerramento{
    ciot: Ciot  
    viagens: Array<Viagem>;
}