import {Component, OnInit, ViewEncapsulation, AfterViewInit, ElementRef, ViewChildren} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControlName} from "@angular/forms";
import {Router} from "@angular/router";

import {Observable} from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

import {GenericValidator} from "../../commom/generic.form.validator";
import {CiotService} from '../../ciot/services/ciot.service';
import {ToastsManager} from 'ng2-toastr';
import {Mensagens} from '../../ciot/util/enums';
import {ReCaptcha} from '../../ciot/models/ReCaptcha';
import {RecaptchaService} from '../../ciot/services/recaptcha.service';
import {ConsultarSituacaoCiotRequest} from "../../ciot/models/consulta-ciot/consultar-situacao-ciot-request";

@Component({
    selector: 'app-retificar',
    templateUrl: './retificar.component.html'
})
export class RetificarComponent implements OnInit, AfterViewInit {
    @ViewChildren(FormControlName, {read: ElementRef}) formInputElements: ElementRef[];

    public errors: any[] = [];
    public meetupForm: FormGroup;
    public displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;
    private validRecaptcha: boolean;

    constructor(private fb: FormBuilder,
                private service: CiotService,
                private toastr: ToastsManager,
                private router: Router,
                private recaptchaService: RecaptchaService) {

        this.validationMessages = {
            ciotRetificar: {
                required: 'O CIOT é requerido.'
            },
            senhaRetificar: {
                required: 'A senha é requerida.'
            }
        };

        this.genericValidator = new GenericValidator(this.validationMessages);
    }

    ngOnInit() {
        this.meetupForm = this.fb.group({
            ciotRetificar: ['', [Validators.required]],
            senhaRetificar: ['', [Validators.required]]
        });
        this.recaptchaService.isValid().subscribe(valid => this.validRecaptcha = valid);
    }

    ngAfterViewInit(): void {
        let controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

        Observable.merge(...controlBlurs).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.meetupForm);
        });
    }

    retificar() {
        if (this.meetupForm.dirty && this.meetupForm.valid) {
            // if (this.validRecaptcha) {
                let request = new ConsultarSituacaoCiotRequest();
                request.ciot = this.meetupForm.value.ciotRetificar;
                request.senhaAlteracao = this.meetupForm.value.senhaRetificar;

                this.service.consultarCiot(request)
                    .subscribe(
                        response => {
                            if (response.Retorno.Sucesso) {
                                if (response.Retorno.TipoViagem != 3) {
                                    this.toastr.error("Não é permitida a retificação de CIOT do tipo padrão", "Oops");
                                    return;
                                }

                                if (response.Retorno.Encerrado) {
                                    this.toastr.error(Mensagens.DECLARACAO_INFORMADA_ENCERRADA, "Oops");
                                    return;
                                }
                                var oneDay=1000*60*60*24;
                                var dataAtualMs = new Date().getTime();
                                var dataFinalMs = new Date(response.Retorno.DataTerminoFrete).getTime();

                                // Calculate the difference in milliseconds
                                var differenceMs = dataAtualMs - dataFinalMs;

                                // Convert back to days and return
                                var dias = Math.round(differenceMs/oneDay);

                                if (dias > 3) {
                                    this.toastr.error(Mensagens.DECLARACAO_INFORMADA_PRAZO_72, "Oops");
                                    return;
                                }
                                this.router.navigate(['/retificacao', this.meetupForm.value.ciotRetificar, this.meetupForm.value.senhaRetificar,], {skipLocationChange: true})
                            } else {
                                this.toastr.error(Mensagens.CIOT_NAO_ENCONTRADO_CREDENCIAIS_INFORMADAS, "Oops");
                            }
                        },
                        error => {
                            this.onError(error)
                        }
                    );
            // } else {
            //     this.toastr.error(Mensagens.INFORME_CAMPO_NAO_SOU_ROBO, Mensagens.OOPS)
            // }
        }
    } 

    onError(serviceReturn) {
        this.errors = Object.assign([], serviceReturn.error.errors);
    }

    onSaveComplete(): void {
        this.meetupForm.reset();
        this.errors = [];

        this.router.navigate(['/meetups']);
    }

    resolved(captchaResponse: string) { 
        if(captchaResponse) {
            this.service.validarTokenReCaptcha(captchaResponse)
            .subscribe(
                result => {
                    this.onValidRecaptcha(result)
                },
                error => {
                    this.onErrorRecaptcha(error);
                });
        } else {
            this.toastr.error(Mensagens.ERRO_VALIDAR_RECAPTCHA, Mensagens.OOPS);
            this.recaptchaService.setValid(false);
        }
    }

    onValidRecaptcha(result: ReCaptcha) {
        if (!result.Retorno) {
            this.toastr.error(Mensagens.ERRO_VALIDAR_RECAPTCHA, Mensagens.OOPS);
            this.recaptchaService.setValid(false);
        } else {
            this.recaptchaService.setValid(true);
        }
    }

    onErrorRecaptcha(error) {
        this.toastr.error(Mensagens.ERRO_CONSULTAR_INFORMACOES_RECAPTCHA, Mensagens.OOPS);
    }
}
