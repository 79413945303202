import {Routes} from "@angular/router";
import {HomeComponent} from "./home/home.component";
import {CiotComponent} from "./ciot/ciot.component";
import {ConsultaCiotComponent} from "./ciot/declaracao/consulta-ciot.component";
import {RetificacaoComponent} from "./ciot/retificacao/retificacao.component";
import {EncerramentoComponent} from "./ciot/encerramento/encerramento.component";
import {ContratanteComponent} from "./ciot/declaracao/contratante/contratante.component";
import {RemetenteComponent} from "./ciot/declaracao/remetente/remetente.component";

export const rootRouterConfig: Routes = [
    { path: "", component: HomeComponent },
    { path: "home", component: HomeComponent },
    { path: "ciot/:NomeRazaoSocialTransportador/:CpfCnpjTransportador/:RNTRCTransportador/:CpfCnpjInteressado", component: CiotComponent},
    { path: "consulta-ciot/:ciot/:senha/:cpfMotorista/:cpfContratante/:cepOrigem/:cepDestino/:fromDeclaracao", component: ConsultaCiotComponent },
    { path: "encerramento/:ciot/:senha", component: EncerramentoComponent },
    { path: "retificacao/:ciot/:senha", component: RetificacaoComponent },
    { path: "declaracao/contratante/:ciot/", component: ContratanteComponent },
    { path: "declaracao/remetente/:ciot/", component: RemetenteComponent }
];
