import { Veiculo } from "../veiculo";
import { Excecao } from "../common/excecao";

export class RetificarOperacaoTransporteRequest {
    ciot: string;
    veiculos: Veiculo[];
    codigoNaturezaCarga: string;
    pesoCarga: number;
    dataInicioViagem: Date;
    dataFimViagem: Date;
    codigoMunicipioOrigem: number;
    codigoMunicipioDestino: number;
    senhaAlteracao: string;
    quantidadeTarifas: number;
    valorTarifas: number;
}

export class RetificarOperacaoTransporteResponse {
    Retorno: DadosRetornoRetificar;
    Sucesso: boolean;
    Mensagem: string
}

class DadosRetornoRetificar {
    id: string;
    Excecao: ExcecaoRetificar;
    CIOT: string;
    DataRetificacao: Date;
    Sucesso: boolean;
}

class ExcecaoRetificar {
    id: string;
    Tipo: number;
    Codigo: string;
    Mensagem: string;
}

