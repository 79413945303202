import { Component, OnInit, ViewEncapsulation, AfterViewInit, ElementRef, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName } from "@angular/forms";
import { Router } from "@angular/router";

import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

import { GenericValidator } from "../../commom/generic.form.validator";
import { CiotService } from '../../ciot/services/ciot.service';
import { ToastsManager } from 'ng2-toastr';
import { ConsultaSituacaoTransportadorRequest, ConsultaSituacaoTransportadorResponse } from '../../ciot/models/consulta-situacao-transportador/consulta-situacao-transportador';
import { Mensagens } from '../../ciot/util/enums';
import { RecaptchaService } from '../../ciot/services/recaptcha.service';
import { ReCaptcha } from '../../ciot/models/ReCaptcha';


@Component({
  selector: 'app-declaracao',
  templateUrl: './declaracao.component.html'
})
export class DeclaracaoComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  public errors: any[] = [];
  public declaracaoForm: FormGroup;
  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  private request: ConsultaSituacaoTransportadorRequest = new ConsultaSituacaoTransportadorRequest();
  private response: ConsultaSituacaoTransportadorResponse = new ConsultaSituacaoTransportadorResponse();
  private validRecaptcha: boolean;
  maskCpfCnpj = '';
  maskCpfCnpjContratante = '';

  constructor(private fb: FormBuilder,
    private router: Router,
    private ciotService: CiotService,
    private toastr: ToastsManager,
    private recaptchaService: RecaptchaService) {

    this.validationMessages = {
      documentoTransportador: {
        required: 'O documento do transportador é obrigatório.',
        minlength: 'O documento do transportador precisa ter no mínimo 11 caracteres',
        maxlength: 'A documento do transportador precisa ter no máximo 14 caracteres'
      },
      documentoContratante: {
        required: 'A documento do contratante é obrigatório.',
        minlength: 'O documento do transportador precisa ter no mínimo 11 caracteres',
        maxlength: 'A documento do transportador precisa ter no máximo 14 caracteres'
      },
      rntrc: {
        required: 'O RNTRC é obrigatório.',
      }
    };

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this.declaracaoForm = this.fb.group({
      documentoTransportador: ['', [Validators.required, Validators.minLength(11)]],
      documentoContratante: ['', [Validators.required, Validators.minLength(11)]],
      rntrc: ['', [Validators.required]],
    });
    this.recaptchaService.isValid().subscribe(valid => this.validRecaptcha = valid);

    this.declaracaoForm.get('documentoTransportador').valueChanges.map(valor=>valor.length).subscribe(length=>{
      if(length>11){
        this.maskCpfCnpj = '00.000.000/0000-00'
      } else {
       this.maskCpfCnpj = '000.000.000-000'
      }

      if (length < 11 && this.maskCpfCnpj == '00.000.000/0000-00') {
       this.maskCpfCnpj = '000.000.000-000'
      }
    })

    this.declaracaoForm.get('documentoContratante').valueChanges.map(valor=>valor.length).subscribe(length=>{
      if(length>11){
        this.maskCpfCnpjContratante = '00.000.000/0000-00'
      } else {
       this.maskCpfCnpjContratante = '000.000.000-000'
      }

      if (length < 11 && this.maskCpfCnpjContratante == '00.000.000/0000-00') {
       this.maskCpfCnpjContratante = '000.000.000-000'
      }
    })
  }

  ngAfterViewInit(): void {
    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    Observable.merge(...controlBlurs).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.declaracaoForm);
    });
  }

  declarar() {
    // Alterado para testes
    if(this.declaracaoForm.value.documentoTransportador.length != 11 && this.declaracaoForm.value.documentoTransportador.length != 14) {
      return this.toastr.error("Preencha o campo CPF/CNPJ Transportador corretamente!");
    }

    if(this.declaracaoForm.value.documentoContratante.length != 11 && this.declaracaoForm.value.documentoContratante.length != 14) {
      return this.toastr.error("Preencha o campo CPF/CNPJ Contratante corretamente!");
    }
    
    // if (this.validRecaptcha) {
      this.request.CpfCnpjTransportador = this.declaracaoForm.value.documentoTransportador;
      this.request.CpfCnpjInteressado = this.declaracaoForm.value.documentoContratante;
      this.request.RNTRCTransportador = this.declaracaoForm.value.rntrc;
      this.ciotService.consultarSituacaoTransportador(this.request)
        .subscribe(
          result => {
            this.onSaveComplete(result, this.request);
          },
          error => {
            this.onError(error);
          });
      // this.router.navigate(['/ciot'], { skipLocationChange: true });
    // } else {
    //   this.toastr.error(Mensagens.INFORME_CAMPO_NAO_SOU_ROBO, Mensagens.OOPS)
    //   return;
    // }
  } 

  onError(serviceReturn) {
    this.errors = Object.assign([], serviceReturn.error.errors);
    this.toastr.error(Mensagens.NAO_POSSIVEL_ENCONTRAR_USUARIO, Mensagens.OOPS);
  }

  onSaveComplete(result: ConsultaSituacaoTransportadorResponse,   request: ConsultaSituacaoTransportadorRequest): void {
    this.response = result;
    
    if (this.response.Retorno.Sucesso) {
      this.router.navigate(['/ciot', this.response.Retorno.NomeRazaoSocialTransportador,
        this.response.Retorno.CpfCnpjTransportador, this.response.Retorno.RNTRCTransportador,
        request.CpfCnpjInteressado], { skipLocationChange: true });
    } else {
      this.toastr.error(this.response.Retorno.Excecao.Mensagem, Mensagens.OOPS);
    }
  }

  resolved(captchaResponse: string) { 
    if(captchaResponse) {
      this.ciotService.validarTokenReCaptcha(captchaResponse)
      .subscribe(
        result => {
          this.onValidRecaptcha(result)
        },
        error => {
          this.onErrorRecaptcha(error);
        });
    } else {
      this.toastr.error(Mensagens.ERRO_VALIDAR_RECAPTCHA, Mensagens.OOPS);
      this.recaptchaService.setValid(false);
    } 
  }

  onValidRecaptcha(result: ReCaptcha) {
    if (!result.Retorno) {
      this.toastr.error(Mensagens.ERRO_VALIDAR_RECAPTCHA, Mensagens.OOPS);
      this.recaptchaService.setValid(false);
    } else {
      this.recaptchaService.setValid(true);
    }
  }

  onErrorRecaptcha(error) {
    this.toastr.error(Mensagens.ERRO_CONSULTAR_INFORMACOES_RECAPTCHA, Mensagens.OOPS);
  }
}