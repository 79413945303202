// https://github.com/sengirab/ngAutocomplete

import { Component, ViewChild, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { CreateNewAutocompleteGroup, SelectedAutocompleteItem, NgAutocompleteComponent } from "ng-auto-complete";
import { CiotService } from "../../ciot/services/ciot.service";
import { ArrayObservable } from "rxjs/observable/ArrayObservable";
import { Cidade } from "../../ciot/models/cidade";
import { AutoCompleteService } from "../../ciot/services/autocomplete.service";
import { ToastsManager } from "ng2-toastr";

@Component({
  selector: 'app-autocomplete-cidade',
  templateUrl: './autocomplete-cidade.component.html',
  styles: [],
})
export class AutocompleteCidadeComponent implements OnInit {

  @ViewChild(NgAutocompleteComponent) public completer: NgAutocompleteComponent;

  static cidadeList: Array<Cidade> = [];
  @Output() choosedCidade = new EventEmitter();

  public group = [
    CreateNewAutocompleteGroup(
      "Selecione",
      'completer',
      AutocompleteCidadeComponent.cidadeList,
      { titleKey: 'nomeSiglaDescricao', childrenKey: null }
    )
  ];

  constructor(private service: CiotService,
    private autocompleteService: AutoCompleteService) {
  }

  ngOnInit(): void {
    let input = this.completer.FindInput('completer');
    
    this.autocompleteService.enviaCompleter(this.completer);
    this.autocompleteService.recebeMensagem().subscribe(mensagem => {
      this.completer.completers.first.group.placeholder = mensagem;
    }, (error) => {
    });
    this.autocompleteService.recebeResetInput().subscribe(component => {

      this.completer.ResetInputs();
    },
      (error) => {
      });

    this.autocompleteService.recebeUF().subscribe(uf => {
      this.consultarCidades(uf);
    },
      (error) => {
      });
  }

  consultarCidades(nome: string): void {
    this.service.consultarCidades(nome)
      .subscribe(
        result => {
          AutocompleteCidadeComponent.cidadeList = result;
          if (this.completer != undefined) {
            this.completer.SetValues("completer", result);
          }
        },
        error => { }
      );
  }

  /**
   *
   * @param item
   * @constructor
   */
  Selected(item: SelectedAutocompleteItem) {
    if (item == undefined || item.item == null) {
      return;
    }
    this.choosedCidade.emit(item.item.original);
  }

  onKeypress(event) {
    if (event.target.value.length >= 3 && event.target.value.replace(/\s/g, "") != "") {
      this.consultarCidades(event.target.value);
    }
  }
}
