
export class ConsultarSituacaoCiotDTO {
    ciot: string;
    senha: string;
    contratado: string;
    rntrc: string;
    dtInicioFrete: string;
    dtTerminoFrete: string;
    valorFrete: number;
    totalImposto: number;
    totalPedagio: number;
    tipoViagem: string;
    encerrada: string;
    aviso: string;
    situacaoDeclaracao: string;
}