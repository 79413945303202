// https://github.com/sengirab/ngAutocomplete

import { Component, ViewChild, Output, EventEmitter } from "@angular/core";
import { CreateNewAutocompleteGroup, SelectedAutocompleteItem, NgAutocompleteComponent } from "ng-auto-complete";
import { CiotService } from "../../ciot/services/ciot.service";
import { ArrayObservable } from "rxjs/observable/ArrayObservable";
import { Tipos } from "../../ciot/models/tipoCarga";

@Component({
  selector: 'app-autocomplete-tipoCarga',
  templateUrl: './autocomplete-tipoCarga.component.html',
  styles: []
})
export class AutocompleteTipoCargaComponent {

  @ViewChild(NgAutocompleteComponent) public completer: NgAutocompleteComponent;
  static tipoCargaList: Array<Tipos> = [];
  static inicializado: boolean = false;

  @Output() choosedTipoCarga = new EventEmitter();

  public group = [
    CreateNewAutocompleteGroup(
      "Selecione",
      'completer',
      AutocompleteTipoCargaComponent.tipoCargaList,
      { titleKey: 'DESCRICAO', childrenKey: null}
    ),
  ];

  constructor(private service: CiotService) {
    this.consultarTipoCarga();
  }

  consultarTipoCarga(): void {
    if (!AutocompleteTipoCargaComponent.inicializado) {
        AutocompleteTipoCargaComponent.inicializado = true;
      this.service.consultarTiposCarga("")
        .subscribe(
          result => {
            AutocompleteTipoCargaComponent.tipoCargaList = result.Retorno;
            if (this.completer != undefined) {
              this.completer.SetValues("completer", result.Retorno);
            }
          },
          error => { }
        );
    } else if (AutocompleteTipoCargaComponent.tipoCargaList.length == 0) {
      setTimeout(() => { this.consultarTipoCarga(); }, 500);
    } else {
      if (this.completer != undefined) {
        this.completer.SetValues("completer", AutocompleteTipoCargaComponent.tipoCargaList);
      }
    }
  }

  /**
   *
   * @param item
   * @constructor
   */
  Selected(item: SelectedAutocompleteItem) {
    if (item == undefined || item.item == null) return;
    this.choosedTipoCarga.emit(item.item.original);
  }
}
