import { Injectable, OnInit } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";
import { NgAutocompleteComponent } from "ng-auto-complete";

@Injectable()
export class CiotTabsService implements OnInit {

    ngOnInit(): void {
        this.subject.next(true);
    }
    private subject = new Subject<boolean>();

    setVisible(show: boolean) {
        this.subject.next(show);
    }

    isVisible(): Observable<boolean> {
        return this.subject.asObservable();
    }
} 