import { Component, OnInit, ViewEncapsulation, AfterViewInit, ElementRef, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName } from "@angular/forms";
import { Router } from "@angular/router";

import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

import { GenericValidator } from "../../../commom/generic.form.validator";

import { DadosRetornoBanco } from '../../models/banco';

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html'
})
export class PagamentoComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  public errors: any[] = [];
  public pagamentoForm: FormGroup;
  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  private title;
  public value: Date = new Date(2000, 2, 10);
  public banco: DadosRetornoBanco;
  maskCpfCnpj = '';

  constructor(private fb: FormBuilder,
    private router: Router) {

    this.validationMessages = {
      formaPagamento: {
        required: 'A data início do frete é obrigatório.'
      },
      parcelaUnica: {
        required: 'A data término do frete é obrigatório.'
      },
      // cidadeOrigem: {
      //   required: 'A cidade origem do contratante é obrigatório.'
      // },
      // cidadeDestino: {
      //   required: 'A cidade destino é obrigatório.',
      // },
      naturezaCarga: {
        required: 'A natureza da carga é obrigatório.',
      },
      valorFrete: {
        required: 'O valor do frete é obrigatório.',
      },
      quantidadeTarifas: {
        required: 'A quantidade de tarifas é obrigatório.',
      },
      valorTotalTarifas: {
        required: 'O valor total das tarifas é obrigatório.',
      }
    };

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this.pagamentoForm = this.fb.group({
      formaPagamento: ['', [Validators.required]],
      banco: ['', [Validators.required]],
      agencia: ['', [Validators.required]],
      conta: ['', [Validators.required]],
      cpfCnpjConta: ['', ],
      // documentoContratante: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(14)]],
      rntrc: ['', [Validators.required]],
    });

    this.pagamentoForm.get('cpfCnpjConta').valueChanges.map(valor=>valor.length).subscribe(length=>{
      if(length>11){
        this.maskCpfCnpj = '00.000.000/0000-00'
      } else {
       this.maskCpfCnpj = '000.000.000-000'
      }

      if (length < 11 && this.maskCpfCnpj == '00.000.000/0000-00') {
       this.maskCpfCnpj = '000.000.000-000'
      }
    })

    this.onChanges();
  }

  ngAfterViewInit(): void {
    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    Observable.merge(...controlBlurs).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.pagamentoForm);
    });
  }

  onChanges() {
    this.pagamentoForm.get("formaPagamento").valueChanges.subscribe(value => {
      if (value == 1 || value == 5) { 

        document.getElementById('idCpfCnpjPagamento').hidden = true;
        document.getElementById('idBancos').hidden = true;
        document.getElementById('idAgenciaConta').hidden = true;
      } else {
        document.getElementById('idCpfCnpjPagamento').hidden = false;
        document.getElementById('idBancos').hidden = false;
        document.getElementById('idAgenciaConta').hidden = false;
      }
    });
  }

  onChoosedBanco(evento) {
    this.banco = new DadosRetornoBanco;
    this.banco.CODIGO = evento.id;
    this.banco.NOME   = evento.nome;
  }

  onError(serviceReturn) {
    this.errors = Object.assign([], serviceReturn.error.errors);
  }

  onSaveComplete(): void {
    this.pagamentoForm.reset();
  }
}
