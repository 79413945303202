import { Component, OnInit, ViewEncapsulation, AfterViewInit, ElementRef, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName } from "@angular/forms";
import { Router } from "@angular/router";

import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

import { GenericValidator } from "../../commom/generic.form.validator";
import { CiotService } from '../../ciot/services/ciot.service';
import { ConsultarSituacaoCiotRequest } from '../../ciot/models/consulta-ciot/consultar-situacao-ciot-request';
import { ToastsManager } from 'ng2-toastr';
import { Mensagens } from '../../ciot/util/enums';
import { ReCaptcha } from '../../ciot/models/ReCaptcha';
import { RecaptchaService } from '../../ciot/services/recaptcha.service';

@Component({
  selector: 'app-consultar',
  templateUrl: './consultar.component.html'
})
export class ConsultarComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  public errors: any[] = [];
  public meetupForm: FormGroup;
  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  private validRecaptcha: boolean;

  constructor(private fb: FormBuilder,
    private service: CiotService,
    private toastr: ToastsManager,
    private router: Router,
    private recaptchaService: RecaptchaService) {

    this.validationMessages = {
      ciot: {
        required: 'O CIOT é obrigatório.'
      },
      senha: {
        required: 'A senha é obrigatório.'
      }
    };

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this.meetupForm = this.fb.group({
      ciot: ['', [Validators.required]],
      senha: ['', [Validators.required]]
    });
    this.recaptchaService.isValid().subscribe(valid => this.validRecaptcha = valid);
  }

  ngAfterViewInit(): void {
    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    Observable.merge(...controlBlurs).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.meetupForm);
    });
  }

  consultar() {
    // if (this.validRecaptcha) {
      if (this.meetupForm.dirty && this.meetupForm.valid) {
        let request = new ConsultarSituacaoCiotRequest();
        request.ciot = this.meetupForm.value.ciot;
        request.senhaAlteracao = this.meetupForm.value.senha;
        this.service.consultarCiot(request)
          .subscribe(
            response => {
              if (response.Retorno.Sucesso) {
                this.router.navigate(['consulta-ciot', request.ciot, request.senhaAlteracao, "123", "123", "123", "123", false], { skipLocationChange: true })
              } else {
                this.toastr.error(Mensagens.CIOT_NAO_ENCONTRADO_CREDENCIAIS_INFORMADAS, Mensagens.OOPS);
              }
            },
            error => { this.onError(error) }
          );
      }
    // } else {
    //   this.toastr.error(Mensagens.INFORME_CAMPO_NAO_SOU_ROBO, Mensagens.OOPS)
    // }
  } 

  onError(serviceReturn) {
    this.errors = Object.assign([], serviceReturn.error.errors);
  }

  onSaveComplete(): void {
    this.meetupForm.reset();
    this.errors = [];

    this.router.navigate(['/meetups']);
  }

  resolved(captchaResponse: string) { 
    if(captchaResponse) {
      this.service.validarTokenReCaptcha(captchaResponse)
      .subscribe(
        result => {
          debugger;
          this.onValidRecaptcha(result)
        },
        error => {
          this.onErrorRecaptcha(error);
        });
    } else {
      this.toastr.error(Mensagens.ERRO_VALIDAR_RECAPTCHA, Mensagens.OOPS);
      this.recaptchaService.setValid(false);
    } 
  }

  onValidRecaptcha(result: ReCaptcha) {
    debugger;
    if (!result.Retorno) {
      this.toastr.error(Mensagens.ERRO_VALIDAR_RECAPTCHA, Mensagens.OOPS);
      this.recaptchaService.setValid(false);
    } else {
      this.recaptchaService.setValid(true);
    }
  }

  onErrorRecaptcha(error) {
    this.toastr.error(Mensagens.ERRO_CONSULTAR_INFORMACOES_RECAPTCHA, Mensagens.OOPS);
  }
}
