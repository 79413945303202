import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChildren} from '@angular/core';
import {FormBuilder, FormControlName, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

import {Observable} from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

import {GenericValidator} from "../../../commom/generic.form.validator";
import {Pessoa} from "../../../ciot/models/pessoa";
import {ContratanteComponent} from '../contratante/contratante.component';
import {RemetenteComponent} from '../remetente/remetente.component';
import {DestinatarioComponent} from '../destinatario/destinatario.component';
import {TransportadorVeiculosComponent} from '../transportador-veiculos/transportador-veiculos.component';
import {Cidade} from "../../models/cidade";
import {RetornoCidades} from "../../models/cidade";
import {AutoCompleteService} from '../../services/autocomplete.service';
import {CiotService} from '../../services/ciot.service';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';

@Component({
    selector: 'app-consignatario',
    templateUrl: './consignatario.component.html',
    styles: []
})
export class ConsignatarioComponent implements OnInit, AfterViewInit {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    public errors: any[] = [];
    public consignatarioForm: FormGroup;
    public veiculo: Pessoa;
    public displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;
    private title;
    public value: Date = new Date(2000, 2, 10);
    public cidade: RetornoCidades;
    maskCpfCnpj = '';

    cidadeList: Array<Cidade> = [];
    noResult = false;
    cidadeCompleterText: string;

    @Input() contratanteComponent: ContratanteComponent;
    @Input() remetenteComponent: RemetenteComponent;
    @Input() destinatarioComponent: DestinatarioComponent;
    @Input() transportadorVeiculosComponent: TransportadorVeiculosComponent;

    constructor(private fb: FormBuilder,
        private autoCompleteService: AutoCompleteService,
        private ciotService: CiotService,
        private router: Router) {

        this.ciotService.consultarCidades("")
            .subscribe(
                result => {
                    this.cidadeList = result;
                },
                error => { }
            );

        this.validationMessages = {
            cpfCnpj: {
                required: 'O CPF/CNPJ é obrigatório.'
            },
            nome: {
                required: 'O nome é obrigatório.'
            },
            cep: {
                required: 'O CEP é obrigatório.',
            },
            logradouro: {
                required: 'O logradouro é obrigatório.',
            },
            cidade: {
                required: 'A cidade é obrigatória.',
            },

        };

        this.genericValidator = new GenericValidator(this.validationMessages);
    }

    // AutoComplete métodos
    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
        this.cidade = undefined;
    }

    onSelectCidade(event: TypeaheadMatch): void {
        this.cidade = event.item;
    }

    ngOnInit() {
        this.consignatarioForm = this.fb.group({
            cpfCnpj: ['', [Validators.required]],
            nome: ['', [Validators.required]],
            cep: ['', [Validators.required]],
            logradouro: ['', [Validators.required]],
            cidade: ['', [Validators.required]],
            rntrc: ['',],
            nomeFantasia: ['',],
            numero: ['',],
            bairro: ['',],
            complemento: ['',],
            telefone: ['',],
            email: ['',]
            // documentoContratante: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(14)]],
        });

        this.consignatarioForm.get('cpfCnpj').valueChanges.map(valor=>valor.length).subscribe(length=>{
            if(length>11){
               this.maskCpfCnpj = '00.000.000/0000-00';
             } else {
                this.maskCpfCnpj = '000.000.000-000';
             }
      
             if (length < 11 && this.maskCpfCnpj == '00.000.000/0000-00') {
                this.maskCpfCnpj = '000.000.000-000';
             }
          })
    }



    ngAfterViewInit(): void {
        let controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

        Observable.merge(...controlBlurs).subscribe(value => {
            this.displayMessage = this.genericValidator.processMessages(this.consignatarioForm);
        });
    }

    ngFocus() {
        let inputField: HTMLElement = <HTMLElement>document.querySelectorAll('.dialog input')[0];
        inputField && inputField.focus();
    }

    // Botão COPIAR
    limpar() {
        if (this.consignatarioForm != undefined) {
            this.consignatarioForm.reset();
            this.cidade =  undefined;
            this.cidadeCompleterText = "";
        }
    }

    copiarContratante() {
        if (this.contratanteComponent != undefined && this.contratanteComponent.contratanteForm.dirty) {
            this.contratanteComponent.contratanteForm.value.cpfCnpj = this.contratanteComponent.CpfCnpjInteressado;
            this.transportadorVeiculosComponent.transportadorForm.value.rntrc = this.transportadorVeiculosComponent.RNTRCTransportador;
            this.consignatarioForm.setValue(this.contratanteComponent.contratanteForm.value);
            this.cidade = this.contratanteComponent.cidade;
            this.cidadeCompleterText = this.contratanteComponent.cidade.NOME;
            this.consignatarioForm.markAsDirty();
        }
    }

    copiarRemetente() {
        if (this.remetenteComponent != undefined && this.remetenteComponent.remetenteForm.dirty) {
            this.consignatarioForm.setValue(this.remetenteComponent.remetenteForm.value);
            this.cidade = this.remetenteComponent.cidade;
            this.cidadeCompleterText = this.remetenteComponent.cidade.NOME;
            this.consignatarioForm.markAsDirty();
        }
    }

    copiarDestinatario() {
        if (this.destinatarioComponent != undefined && this.destinatarioComponent.destinatarioForm.dirty) {
            this.consignatarioForm.setValue(this.destinatarioComponent.destinatarioForm.value);
            this.cidade = this.destinatarioComponent.cidade;
            this.cidadeCompleterText = this.destinatarioComponent.cidade.NOME;
            this.consignatarioForm.markAsDirty();
        }
    }

    copiarTransportador() {
        if (this.transportadorVeiculosComponent != undefined && this.transportadorVeiculosComponent.transportadorForm.dirty) {
            this.transportadorVeiculosComponent.transportadorForm.value.cpfCnpj = this.transportadorVeiculosComponent.CpfCnpjTransportador;
            this.transportadorVeiculosComponent.transportadorForm.value.cpfCnpj = this.transportadorVeiculosComponent.CpfCnpjTransportador;
            this.transportadorVeiculosComponent.transportadorForm.value.nome = this.transportadorVeiculosComponent.NomeRazaoSocialTransportador;
            this.transportadorVeiculosComponent.transportadorForm.value.nomeFantasia = this.transportadorVeiculosComponent.NomeRazaoSocialTransportador;
            this.consignatarioForm.setValue(this.transportadorVeiculosComponent.transportadorForm.value);
            this.cidade = this.transportadorVeiculosComponent.cidade;
            this.cidadeCompleterText = this.transportadorVeiculosComponent.cidade.NOME;
            this.consignatarioForm.markAsDirty();
        }
    }

    onError(serviceReturn) {
        this.errors = Object.assign([], serviceReturn.error.errors);
    }

    onSaveComplete(): void {
        this.consignatarioForm.reset();
    }
}
