import { Component, OnInit, ViewEncapsulation, AfterViewInit, ElementRef, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName } from "@angular/forms";
import { Router } from "@angular/router";

import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

import { GenericValidator } from "../../../commom/generic.form.validator";
import { Veiculo } from "../../../ciot/models/veiculo"

import { PatternValidator } from '@angular/forms/src/directives/validators';

@Component({
  selector: 'app-motorista',
  templateUrl: './motorista.component.html',
  styles: []
})
export class MotoristaComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  public errors: any[] = [];
  public motoristaForm: FormGroup;
  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  private title;
  public value: Date = new Date(2000, 2, 10);

  constructor(private fb: FormBuilder,
    private router: Router) {

      this.validationMessages = {
        cpf: {
          required: 'O CPF é obrigatório.'
        },
        nome: {
          required: 'O nome é obrigatório.'
        },
        cnh: {
          required: 'O CNH é obrigatório.',
        }
      };

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this.motoristaForm = this.fb.group({
      cpf: ['', ],
      nome: ['', ],
      cnh: ['', ]
      // documentoContratante: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(14)]],
    });
  }

  ngAfterViewInit(): void {
    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    Observable.merge(...controlBlurs).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.motoristaForm);
    });
  }

  avancar() {
    this.router.navigate(['/ciot']);
  }

  onError(serviceReturn) {
    this.errors = Object.assign([], serviceReturn.error.errors);
  }

  onSaveComplete(): void {
    this.motoristaForm.reset();
  }
}
