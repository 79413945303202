// https://github.com/sengirab/ngAutocomplete

import { Component, ViewChild, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { CreateNewAutocompleteGroup, SelectedAutocompleteItem, NgAutocompleteComponent } from "ng-auto-complete";
import { CiotService } from "../../ciot/services/ciot.service";
import { ArrayObservable } from "rxjs/observable/ArrayObservable";
import { NaturezaCarga } from "../../ciot/models/natureza-carga";
import { AutoCompleteService } from "../../ciot/services/autocomplete.service";
import { ToastsManager } from "ng2-toastr";
import { AutoCompleteNaturezaService } from "../../ciot/services/autocomplete-natureza.service";

@Component({
  selector: 'app-autocomplete-natureza-carga',
  templateUrl: './autocomplete-natureza-carga.component.html',
  styles: []
})
export class AutoCompleteNaturezaCargaComponent implements OnInit {

  @ViewChild(NgAutocompleteComponent) public completer: NgAutocompleteComponent;
  
  static naturezaCargaList: Array<NaturezaCarga> = [];

  @Output() choosedNatureza = new EventEmitter();

  public group = [
    CreateNewAutocompleteGroup(
      "Selecione",
      'completerNatureza',
      AutoCompleteNaturezaCargaComponent.naturezaCargaList,
      { titleKey: 'descricao', childrenKey: null }
    ),
  ];

  constructor(private service: CiotService,
    private autoCompleteService: AutoCompleteService,
    private autoCompleteNaturezaService: AutoCompleteNaturezaService) {
  }

  ngOnInit(): void {
    this.autoCompleteService.recebeResetInput().subscribe(component => {
      this.completer.ResetInputs();
    },
      (error) => {
      });
    this.autoCompleteService.enviaCompleter(this.completer);
    this.autoCompleteNaturezaService.enviaCompleter(this.completer);
  }

  consultarNatureza(descricao: string): void {
    this.service.consultarNaturezaCarga(descricao)
      .subscribe(
        result => {
          AutoCompleteNaturezaCargaComponent.naturezaCargaList = result;
          if (this.completer != undefined) {
            this.completer.SetValues("completerNatureza", result);
          }
        },
        error => { }
      );
  }


  /**
   *
   * @param item
   * @constructor
   */
  Selected(item: SelectedAutocompleteItem) {
    if (item == undefined || item.item == null) {
      return;
    }
    this.choosedNatureza.emit(item.item.original);
  }

  onKeypress(event) {
    if (event.target.value.length >= 3 && event.target.value.replace(/\s/g, "") != "") {
      this.consultarNatureza(event.target.value);
    }
  }
}
