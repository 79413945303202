import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs/Observable";
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';

import { Ciot, CiotRetificacao } from "../models/ciot";
import { DataReturn } from "../models/base/data-return";
import { ViagemEncerramento } from "../models/viagem";
import { Banco } from "../models/banco";
import { Cidade } from "../models/cidade";
import { NaturezaCarga } from "../models/natureza-carga";
import { CancelarOperacaoTransporte } from "../models/cancelar-operacao-transporte";
import { DeclararOperacaoTransporte } from "../models/declarar-operacao-transporte";
import { ConsultarSituacaoCiotRequest } from "../models/consulta-ciot/consultar-situacao-ciot-request";
import { ConsultarSituacaoCiot } from "../models/consulta-ciot/consultar-situacao-ciot-response";
import { CiotResponse } from "../models/consulta-ciot/consulta-ciot-response";
import { ConsultaSituacaoTransportadorRequest, ConsultaSituacaoTransportadorResponse } from "../models/consulta-situacao-transportador/consulta-situacao-transportador";
import { RetificarOperacaoTransporteRequest, RetificarOperacaoTransporteResponse } from "../models/retificacao/retificar-operacao-transporte";
import { DadosRetificar } from "../models/retificacao/dados-retificar";
import { DadosEncerramento } from "../models/encerramento/dados-encerramento";
import { EncerrarOperacaoTransporteRequest, EncerrarOperacaoTransporteResponse } from "../models/encerramento/encerrar-operacao-transporte";
import { Estado } from "../models/estado";
import { ReCaptcha } from "../models/ReCaptcha";
import { environment } from "../../../environments/environment";
import { objEncerrar } from "../models/encerramento/obj-encerrar";
import { objRetificar } from "../models/retificacao/obj-retificar";
import { ObjImprimir } from "../models/objImprimir";
import { TipoCarga } from "../models/tipoCarga";
import { CodigoIBGE } from "../models/codigoIBGE";

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'x-auth-token': 'sotran-sotran-sotrantech',
        // 'x-audit-user-doc': '00000000000'
        'Access-Control-Allow-Origin': '*'

    })
};

@Injectable()
export class CiotService {

    public static UrlBase: string = "";
    public static UrlWeb: string = "";

    constructor(private http: HttpClient) {
        if (CiotService.UrlBase == "") {
            CiotService.UrlBase = environment.url + "";
        }

        if (CiotService.UrlWeb == "") {
            CiotService.UrlWeb = CiotService.UrlBase + "";
        }
    }

    consultarBancos(): Observable<Banco> {
        return this.http
            .get<Banco>(CiotService.UrlWeb + "Operacoes/GetBancos")
            .pipe(
                tap(banco => this.log(`adicionado ciot`))
            );
    }

    consultarEstado(): Observable<Array<Estado>> {
        return this.http
            .get<Array<Estado>>(CiotService.UrlWeb + "Consultas/Estados")
            .pipe(
                tap(estado => this.log(`Consulta de estados`))
            );
    }

    consultarTiposCarga(txt: string): Observable<TipoCarga> {
        return this.http
            .post<TipoCarga>(CiotService.UrlWeb + "Operacoes/GetTiposCarga", txt)
            .pipe(
                tap((ciot: TipoCarga) => this.log(`consulta tipo carga`))
            );
    }

    consultarCidadesByIBGE(descricao: string): Observable<CodigoIBGE> {
        return this.http
            .get<CodigoIBGE>(CiotService.UrlWeb + "Operacoes/GetCidadesByIBGE?descricao=" + descricao)
            .pipe(
                tap((ciot: CodigoIBGE) => this.log(`Consulta de cidades`))
            );
    }

    consultarCidades(nome: string): Observable<Array<Cidade>> {
        return this.http
            .get<Array<Cidade>>(CiotService.UrlWeb + "Operacoes/GetCidades?nome=" + nome)
            .pipe(
                tap(cidade => this.log(`Consulta de cidades`))
            );
    }

    consultarNaturezaCarga(descricao: string): Observable<Array<NaturezaCarga>> {
        return this.http
            .get<Array<NaturezaCarga>>(CiotService.UrlWeb + "Operacoes/GetNaturezasCarga?descricao=" + descricao)
            .pipe(
                tap(result => this.log(`Consulta Natureza`))
            );
    }

    consultarNaturezaCargaById(id: string): Observable<Array<NaturezaCarga>> {
        return this.http
            .get<Array<NaturezaCarga>>(CiotService.UrlWeb + "Operacoes/GetNaturezasCarga?id=" + id, httpOptions)
            .pipe(
                tap(result => this.log(`Consulta Natureza por ID`))
            );
    }

    cancelarOperacaoTransporte(model: CancelarOperacaoTransporte): Observable<DataReturn<Ciot>> {
        return this.http
            .post<DataReturn<Ciot>>(CiotService.UrlWeb + "Operacoes/CancelarOperacaoTransporte", model)
            .pipe(
                tap((ciot: DataReturn<Ciot>) => this.log(`Operação de transporte cancelado`))
            );
    }

    declararOperacaoTransporte(model: DeclararOperacaoTransporte): Observable<DataReturn<Ciot>> {
        return this.http
            .post<DataReturn<Ciot>>(CiotService.UrlWeb + "Operacoes/DeclararOperacaoTransporte", model)
            .pipe(
                tap((ciot: DataReturn<Ciot>) => this.log(`Operação de transporte declarada`))
            );
    }

    consultarCiot(model: ConsultarSituacaoCiotRequest): Observable<CiotResponse> {
        return this.http
            .post<CiotResponse>(CiotService.UrlWeb + "Operacoes/ConsultarSituacaoCiot", model)
            .pipe(
                tap((response: CiotResponse) => this.log(`Consulta de CIOT`))
            );
    }

    consultarSituacaoTransportador(model: ConsultaSituacaoTransportadorRequest): Observable<ConsultaSituacaoTransportadorResponse> {
        return this.http
            .post<ConsultaSituacaoTransportadorResponse>(CiotService.UrlWeb + "Operacoes/ConsultarTransportador", model)
            .pipe(
                tap((ciot: ConsultaSituacaoTransportadorResponse) => this.log(`Consulta de CIOT`))
            );
    }

    retificarOperacaoTransporte(model: RetificarOperacaoTransporteRequest): Observable<RetificarOperacaoTransporteResponse> {
        return this.http
            .post<RetificarOperacaoTransporteResponse>(CiotService.UrlWeb + "Operacoes/RetificarOperacaoTransporte", model)
            .pipe(
                tap((ciot: RetificarOperacaoTransporteResponse) => this.log(`Retificação de CIOT`))
            );
    }

    consultarRetificacao(objCiot: objRetificar): Observable<DadosRetificar> {
        return this.http
            .post<DadosRetificar>(CiotService.UrlWeb + "Operacoes/GetDadosRetificar", objCiot)
            .pipe(
                tap((ciot: DadosRetificar) => this.log(`Retificação de CIOT`))
            );
    }

    consultarEncerramento(objCiot: objEncerrar): Observable<DadosEncerramento> {
        return this.http
            .post<DadosEncerramento>(CiotService.UrlWeb + "Operacoes/GetDadosEncerrar", objCiot)
            .pipe(
                tap((ciot: DadosEncerramento) => this.log(`Encerramento de CIOT`))
            );
    }

    gestaoCiot(objImprimir: ObjImprimir): Observable<DadosEncerramento> {
        return this.http
            .post<DadosEncerramento>(CiotService.UrlWeb + "Operacoes/gestaoCiot", objImprimir)
            .pipe(
                tap((ciot: DadosEncerramento) => this.log(`Gestao de CIOT`))
            );
    }

    encerrarOperacaoTransporte(model: EncerrarOperacaoTransporteRequest): Observable<EncerrarOperacaoTransporteResponse> {
        return this.http
            .post<EncerrarOperacaoTransporteResponse>(CiotService.UrlWeb + "Operacoes/EncerrarOperacaoTransporte", model)
            .pipe(
                tap((ciot: EncerrarOperacaoTransporteResponse) => this.log(`Retificação de CIOT`))
            );
    }

    validarTokenReCaptcha(token: string): Observable<ReCaptcha> {
        return this.http
            .get<ReCaptcha>(CiotService.UrlWeb + "Operacoes/ValidarReCaptcha?token=" + token)
            .pipe(
                tap((ciot: ReCaptcha) => this.log(`Google ReCaptcha`))
            );
    }

    private log(message: string) {
        console.log(message);
    }
}