import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CiotService } from '../ciot/services/ciot.service';
import { ToastsManager } from 'ng2-toastr';
import { Mensagens } from '../ciot/util/enums';
import { ReCaptcha } from '../ciot/models/ReCaptcha';
import { RecaptchaService } from '../ciot/services/recaptcha.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent {

  constructor(private service: CiotService,
    private toastr: ToastsManager) {
  }
}