import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

// 3s
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TabsModule } from "ngx-bootstrap/tabs";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { SelectModule } from "ng2-select";
import { NgAutoCompleteModule } from "ng-auto-complete";
import { ToastModule, ToastOptions } from 'ng2-toastr/ng2-toastr';
import { ToastCustomOptions } from "./custom-module/ToastCustomOptions";
import { NgxBrModule } from "@nbfontana/ngx-br";
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('ptBr', ptBrLocale);

// Captcha
import { RecaptchaModule } from 'ng-recaptcha';

// components
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { CiotComponent } from "./ciot/ciot.component";

import { MenuSuperiorComponent } from "./navigation/menu-superior/menu-superior.component";
import { FooterComponent } from "./navigation/footer/footer.component";

import { DeclaracaoComponent } from "./home/declaracao/declaracao.component";
import { RetificarComponent } from "./home/retificar/retificar.component";
import { CancelarComponent } from "./home/cancelar/cancelar.component";
import { EncerrarComponent } from "./home/encerrar/encerrar.component";
import { ConsultarComponent } from "./home/consultar/consultar.component";

import { ViagemComponent } from "./ciot/declaracao/viagem/viagem.component";
import { PagamentoComponent } from "./ciot/declaracao/pagamento/pagamento.component";
import { TransportadorVeiculosComponent } from "./ciot/declaracao/transportador-veiculos/transportador-veiculos.component";
import { MotoristaComponent } from "./ciot/declaracao/motorista/motorista.component";
import { ContratanteComponent } from "./ciot/declaracao/contratante/contratante.component";
import { RemetenteComponent } from "./ciot/declaracao/remetente/remetente.component";
import { DestinatarioComponent } from "./ciot/declaracao/destinatario/destinatario.component";
import { ConsignatarioComponent } from "./ciot/declaracao/consignatario/consignatario.component";
import { ConsultaCiotComponent } from "./ciot/declaracao/consulta-ciot.component";
import { RetificacaoComponent } from "./ciot/retificacao/retificacao.component";
import { EncerramentoComponent } from './ciot/encerramento/encerramento.component';
import { SalvarDeclaracaoComponent } from './ciot/declaracao/salvar-declaracao/salvar-declaracao.component';

// componentes de tela

import { DemoDropdownBasicComponent } from "./components/drop-down/dropdown-basic";
import { NgSelectCidade } from "./components/ng-select-cidade/ng-select-cidade";
import { CpfInput } from "./components/cpf-input/cpf-input";
import { CnpjInput } from "./components/cnpj-input/cnpj-input";
import { CepInput } from "./components/cep-input/cep-input";
import { EstadosSelect } from "./components/estados-select/estados-select";
import { TelefoneInput } from "./components/telefone-input/telefone-input";
import { AutocompleteCidadeComponent } from './components/autocomplete-cidade/autocomplete-cidade.component';
import { AutocompleteEstadoComponent } from "./components/autocomplete-estado/autocomplete-estado.component";
import { AutoCompleteNaturezaCargaComponent } from './components/autocomplete-natureza-carga/autocomplete-natureza-carga.component';
import { AutocompleteBancosComponent } from './components/autocomplete-bancos/autocomplete-bancos.component';
import { AutocompleteTipoCargaComponent } from './components/autocomplete-tipoCarga/autocomplete-tipoCarga.component';
import { AutocompleteCodigoIBGEComponent } from './components/autocomplete-codIBGE/autocomplete-codIBGE.component';
import { ToastrComponent } from "./components/toastr/toastr.component";

// import the Animations module
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// import the ButtonsModule
import { ButtonsModule } from "@progress/kendo-angular-buttons";

// services
import { CiotService } from "./ciot/services/ciot.service";
import { AutoCompleteService } from "./ciot/services/autocomplete.service";
import { AutoCompleteNaturezaService } from "./ciot/services/autocomplete-natureza.service";
import { CiotTabsService } from "./ciot/services/ciot-tabs.service";
import { RecaptchaService } from "./ciot/services/recaptcha.service";
import { OnlyNumber } from './directives/onlynumber.directive';
import { RntrcMask } from './directives/rntrcMask.directive';
import { SpecialCharacterNotAllowed } from './directives/SpecialCharacterNotAllowed.directive';
import { PlacaAutomovel } from "./directives/placaAutomovel.directive";
import { PlacaAutomovelPipe } from "./pipes/placa-automovel-pipe";

// config
import { rootRouterConfig } from "./app.routes";

//mask
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from "ng2-currency-mask";

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CiotComponent,
    MenuSuperiorComponent,
    FooterComponent,
    DeclaracaoComponent,
    RetificarComponent,
    CancelarComponent,
    EncerrarComponent,
    ConsultarComponent,
    ViagemComponent,
    PagamentoComponent,
    DemoDropdownBasicComponent,
    NgSelectCidade,
    TransportadorVeiculosComponent,
    CpfInput,
    CnpjInput,
    CepInput,
    EstadosSelect,
    TelefoneInput,
    MotoristaComponent,
    ContratanteComponent,
    RemetenteComponent,
    DestinatarioComponent,
    ConsignatarioComponent,
    ConsultaCiotComponent,
    RetificacaoComponent,
    EncerramentoComponent,
    AutocompleteCidadeComponent,
    AutocompleteEstadoComponent,
    AutoCompleteNaturezaCargaComponent,
    AutocompleteBancosComponent,
    AutocompleteTipoCargaComponent,
    AutocompleteCodigoIBGEComponent,
    OnlyNumber,
    RntrcMask,
    SpecialCharacterNotAllowed,
    PlacaAutomovel,
    SalvarDeclaracaoComponent,
    ToastrComponent,
    PlacaAutomovelPipe
  ],
  imports: [
    CurrencyMaskModule,
    NgxMaskModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    NgxBrModule.forRoot(),
    BrowserAnimationsModule,
    ButtonsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    SelectModule,
    FormsModule,
    NgAutoCompleteModule,
    ToastModule.forRoot(),
    NgProgressModule.forRoot({
      color: 'red',
      thick: true
    }),
    NgProgressHttpModule,
    TypeaheadModule.forRoot(),
    RecaptchaModule.forRoot(),
    RouterModule.forRoot(rootRouterConfig, { useHash: false })
  ],
  providers: [
    CiotService,
    AutoCompleteService,
    AutoCompleteNaturezaService,
    CiotTabsService,
    RecaptchaService,
    ContratanteComponent,
    RemetenteComponent,
    { provide: ToastOptions, useClass: ToastCustomOptions },
    { provide: LOCALE_ID, useValue: "pt" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
