import { Component, OnInit, ViewEncapsulation, AfterViewInit, ElementRef, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName } from "@angular/forms";
import { Router } from "@angular/router";

import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

import { GenericValidator } from "../../commom/generic.form.validator";
import { ConsultarSituacaoCiotRequest } from '../../ciot/models/consulta-ciot/consultar-situacao-ciot-request';
import { CiotService } from '../../ciot/services/ciot.service';
import { ToastsManager } from 'ng2-toastr';
import { Mensagens } from '../../ciot/util/enums';
import { ReCaptcha } from '../../ciot/models/ReCaptcha';
import { RecaptchaService } from '../../ciot/services/recaptcha.service';

@Component({
  selector: 'app-encerrar',
  templateUrl: './encerrar.component.html'
})
export class EncerrarComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  public errors: any[] = [];
  public meetupForm: FormGroup;
  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  private validRecaptcha: boolean;

  constructor(private fb: FormBuilder,
    private service: CiotService,
    private toastr: ToastsManager,
    private router: Router,
    private recaptchaService: RecaptchaService) {

    this.validationMessages = {
      ciotEncerrar: {
        required: 'O CIOT é obrigatório.'
      },
      senhaEncerrar: {
        required: 'A senha é obrigatória'
      }
    };

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this.meetupForm = this.fb.group({
      ciotEncerrar: ['', [Validators.required]],
      senhaEncerrar: ['', [Validators.required]]
    });
    this.recaptchaService.isValid().subscribe(valid => this.validRecaptcha = valid);
  }

  ngAfterViewInit(): void {
    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    Observable.merge(...controlBlurs).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.meetupForm);
    });
  }

  encerrar() {
    // if (this.validRecaptcha) {
      let request = new ConsultarSituacaoCiotRequest();
      request.ciot = this.meetupForm.value.ciotEncerrar;
      request.senhaAlteracao = this.meetupForm.value.senhaEncerrar;

      this.service.consultarCiot(request)
        .subscribe(
          response => {
            if (response.Retorno.Sucesso) {
              if(response.Retorno.Encerrado){
                  this.toastr.error(Mensagens.DECLARACAO_INFORMADA_ENCERRADA, "Oops");
                  return;
              }
              this.router.navigate(['/encerramento', this.meetupForm.value.ciotEncerrar, this.meetupForm.value.senhaEncerrar], { skipLocationChange: true });
            } else {
              this.toastr.error(Mensagens.CIOT_NAO_ENCONTRADO_CREDENCIAIS_INFORMADAS, "Oops");
            }
          },
          error => { this.onError(error) }
        );
    // } else {
    //   this.toastr.error(Mensagens.INFORME_CAMPO_NAO_SOU_ROBO, Mensagens.OOPS)
    // }
  } 

  onError(serviceReturn) {
    this.errors = Object.assign([], serviceReturn.error.errors);
    this.toastr.error(Mensagens.CIOT_NAO_ENCONTRADO_CREDENCIAIS_INFORMADAS, "Oops");
  }

  onSaveComplete(): void {
    this.meetupForm.reset();
  }

  resolved(captchaResponse: string) {
    if(captchaResponse) {
      this.service.validarTokenReCaptcha(captchaResponse)
      .subscribe(
        result => {
          this.onValidRecaptcha(result)
        },
        error => {
          this.onErrorRecaptcha(error);
        });
    } else {
      this.toastr.error(Mensagens.ERRO_VALIDAR_RECAPTCHA, Mensagens.OOPS);
      this.recaptchaService.setValid(false);
    } 
  }

  onValidRecaptcha(result: ReCaptcha) {
    if (!result.Retorno) {
      this.toastr.error(Mensagens.ERRO_VALIDAR_RECAPTCHA, Mensagens.OOPS);
      this.recaptchaService.setValid(false);
    } else {
      this.recaptchaService.setValid(true);
    }
  }

  onErrorRecaptcha(error) {
    this.toastr.error(Mensagens.ERRO_CONSULTAR_INFORMACOES_RECAPTCHA, Mensagens.OOPS);
  }
}
