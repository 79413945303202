export enum Sexo {
    Feminino = "F",
    Masculino = "M"
}

export enum TipoPessoa {
    Fisica = "F",
    Juridica = "J"
}

export enum TipoViagem {
    Padrao = "1",
    TAC_Agregado = "3"
}

export enum Mensagens {
    CIOT_NAO_ENCONTRADO_CREDENCIAIS_INFORMADAS = "Não foi encontrado CIOT com as credenciais informadas!",
    NAO_POSSIVEL_ENCONTRAR_USUARIO = "Não foi possível encontrar este usuário",
    OOPS = "Oops!",
    SUCESSO = "Sucesso!",
    NAO_POSSIVEL_CANCELAR_CIOT = 'Não foi possível cancelar este CIOT.',
    PREENCHA_ABA_DESTINATARIO = "Por favor, preencha a aba Destinatário.",
    NAO_FOI_POSSIVEL_DECLARAR_CIOT = 'Não foi possível declarar este CIOT.',
    CIOT_RETIFICADO_SUCESSO = 'CIOT retificado com sucesso!',
    PADRAO = 'Padrão',
    TAC_AGREGADO = 'TAC Agregado',
    INFORME_CAMPO_NAO_SOU_ROBO = 'Por favor, informe o campo "Não sou um robô"',
    ERRO_CONSULTAR_INFORMACOES_RECAPTCHA = "Erro ao consultar informações do ReCaptcha",
    ERRO_VALIDAR_RECAPTCHA = "Erro ao validar ReCaptcha, ou a validação Expirou",
    CIOT_GERADO = "CIOT Gerado",
    CIOT_RETIFICADO = "CIOT Retificado",
    CONTIGENCIA = "Contigência",
    CANCELADO = "Cancelado",
    CIOT_GERADO_SUCESSO = "CIOT declarado com sucesso",
    DECLARACAO_INFORMADA_ENCERRADA = "A declaração informada já foi encerrada.",
    DECLARACAO_INFORMADA_PRAZO_72 = "A declaração não pode ser retificada pois ultrapassou o prazo de 72 horas após o fim da viagem.",
    DECLARACAO_INFORMADA_CANCELAMENTO_5DIAS = "A declaração não pode ser cancelada pois ultrapassou o prazo de 120 horas após o início da viagem.",
}

export enum StatusCiot {
    CIOT_GERADO = 1,
    CONTIGENCIA = 2,
    CANCELADO = 3
}