import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";

@Injectable()
export class RecaptchaService {

    private validRecaptcha = new Subject<boolean>();

    setValid(valid: boolean) {
        this.validRecaptcha.next(valid);
    }

    isValid(): Observable<boolean> {
        return this.validRecaptcha.asObservable();
    }
} 