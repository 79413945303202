import {Component, Input, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {ToastsManager} from 'ng2-toastr';
import {
    Consignatario,
    DeclararOperacaoTransporte,
    Endereco,
    Frete,
    Motorista,
    InformacoesPagamento,
    FreteRetorno,
    Pagamento,
    Valores,
    DadosBancarios
} from '../../models/declarar-operacao-transporte';
import {CiotService} from '../../services/ciot.service';
import {ContratanteComponent} from '../contratante/contratante.component';
import {DestinatarioComponent} from '../destinatario/destinatario.component';
import {ConsignatarioComponent} from '../consignatario/consignatario.component';
import {TransportadorVeiculosComponent} from '../transportador-veiculos/transportador-veiculos.component';
import {RemetenteComponent} from '../remetente/remetente.component';
import {ViagemComponent} from '../viagem/viagem.component';
import {PagamentoComponent} from '../pagamento/pagamento.component';
import {MotoristaComponent} from '../motorista/motorista.component';
import {ViagemDTO} from '../../models/declaracao-dto/viagem-dto';
import {MotoristaDTO} from '../../models/declaracao-dto/motorista-dto';
import {PagamentoDTO} from "../../models/declaracao-dto/pagamento-dto";
import {DadosPessoaisDTO} from '../../models/declaracao-dto/dados-pessoais';
import {Mensagens, TipoPessoa, TipoViagem} from '../../util/enums';

@Component({
  selector: 'app-salvar-declaracao',
  templateUrl: './salvar-declaracao.component.html',
  styles: []
})
export class SalvarDeclaracaoComponent {

  public errors: any[] = [];
  public declaracao: DeclararOperacaoTransporte = new DeclararOperacaoTransporte;
  private viagemDTO: ViagemDTO;
  private motoristaDTO: MotoristaDTO;
  private pagamentoDTO: PagamentoDTO;
  private dadosPessoaisDTO: DadosPessoaisDTO
  private valid: boolean = true;
  private validFreteRetorno: boolean = false;

  // Declaração form inputs
  @Input() viagemComponent: ViagemComponent;
  @Input() pagamentoComponent: PagamentoComponent;
  @Input() transportadorVeiculosComponent: TransportadorVeiculosComponent;
  @Input() motoristaComponent: MotoristaComponent;

  @Input() contratanteComponent: ContratanteComponent;
  @Input() remetenteComponent: RemetenteComponent;
  @Input() destinatarioComponent: DestinatarioComponent;
  @Input() consignatarioComponent: ConsignatarioComponent;

  constructor(private ciotService: CiotService,
    private vRef: ViewContainerRef,
    private toastr: ToastsManager,
    private router: Router) {
  }

  salvar() {
    this.valid = true;
    this.populaFrete();
    this.populaPagamento();
    this.populaTransportadorVeiculos();
    this.populaMotorista();
    this.populaContratante();
    if (TipoViagem.Padrao == this.viagemComponent.tipoViagem) {
      this.populaRemetente();
      this.populaDestinatario();
      this.populaConsignatario();
    }
    if (this.valid) {
      this.declaracao.ciotAjuste = "";
      this.ciotService.declararOperacaoTransporte(this.declaracao)
        .subscribe(
          result => {
            this.onSaveComplete(result)
          },
          error => {
            this.onError(error);
          });
    }
  }

  private populaTransportadorVeiculos() {
    if (this.transportadorVeiculosComponent != undefined && this.transportadorVeiculosComponent.transportadorForm.dirty) {
      this.dadosPessoaisDTO = this.transportadorVeiculosComponent.transportadorForm.value;
      this.declaracao.frete.proprietario = new Consignatario;
      this.declaracao.frete.proprietario.cpfCnpj = this.transportadorVeiculosComponent.CpfCnpjTransportador;
      this.declaracao.frete.proprietario.tipoPessoa = (this.declaracao.frete.proprietario.cpfCnpj != undefined
        && this.declaracao.frete.proprietario.cpfCnpj.length == 11) ? TipoPessoa.Fisica : TipoPessoa.Juridica;
      this.declaracao.frete.proprietario.nomeFantasia = this.transportadorVeiculosComponent.NomeRazaoSocialTransportador;
      this.declaracao.frete.proprietario.rntrc = this.transportadorVeiculosComponent.RNTRCTransportador.length == 9 && this.transportadorVeiculosComponent.RNTRCTransportador.substring(1,0) == "0" ? this.transportadorVeiculosComponent.RNTRCTransportador.substring(9,1) : this.transportadorVeiculosComponent.RNTRCTransportador;
      this.declaracao.frete.proprietario.endereco = new Endereco;
      this.declaracao.frete.proprietario.nomeRazaoSocial = this.transportadorVeiculosComponent.NomeRazaoSocialTransportador;
      this.declaracao.frete.proprietario.endereco.bairro = this.dadosPessoaisDTO.bairro;
      this.declaracao.frete.proprietario.endereco.cep = this.dadosPessoaisDTO.cep.replace("-", "");
      if (this.transportadorVeiculosComponent.cidade != undefined) {
        this.declaracao.frete.proprietario.endereco.codigoMunicipio = this.transportadorVeiculosComponent.cidade.ID_CIDADE;
      }
      this.declaracao.frete.proprietario.endereco.complemento = this.dadosPessoaisDTO.complemento;
      this.declaracao.frete.proprietario.endereco.email = this.dadosPessoaisDTO.email;
      this.declaracao.frete.proprietario.endereco.logradouro = this.dadosPessoaisDTO.logradouro;
      this.declaracao.frete.proprietario.endereco.numero = this.dadosPessoaisDTO.numero;
      this.declaracao.frete.proprietario.endereco.telefone = this.dadosPessoaisDTO.telefone;
      // this.declaracao.contratante.tipoPessoa = 
      this.declaracao.veiculos = this.transportadorVeiculosComponent.veiculoList;
      this.executarValidacoesTransportador();
    }

    else {
      this.toastr.error('Por favor, preencha a aba Transportador/Veículos.', Mensagens.OOPS);
      this.valid = false;
      throw new DOMException();
    }
  }

  private populaConsignatario() {
    if (this.consignatarioComponent != undefined) {
      this.dadosPessoaisDTO = this.consignatarioComponent.consignatarioForm.value;
      if (this.dadosPessoaisDTO.cpfCnpj.toString() == "" || this.dadosPessoaisDTO.nome.toString() == "") {
        return;
      }
      this.declaracao.consignatario = new Consignatario;
      this.declaracao.consignatario.cpfCnpj = this.dadosPessoaisDTO.cpfCnpj;
      this.declaracao.consignatario.tipoPessoa = (this.declaracao.consignatario.cpfCnpj != undefined
        && this.declaracao.consignatario.cpfCnpj.length == 11) ? TipoPessoa.Fisica : TipoPessoa.Juridica;
      this.declaracao.consignatario.nomeFantasia = this.dadosPessoaisDTO.nomeFantasia;
      //this.declaracao.consignatario.rntrc = this.dadosPessoaisDTO.rntrc;
      this.declaracao.consignatario.endereco = new Endereco;
      this.declaracao.consignatario.nomeRazaoSocial = this.dadosPessoaisDTO.nome;
      this.declaracao.consignatario.endereco.bairro = this.dadosPessoaisDTO.bairro;
      this.declaracao.consignatario.endereco.cep = this.dadosPessoaisDTO.cep.replace("-", "");
      if (this.consignatarioComponent.cidade != undefined) {
        this.declaracao.consignatario.endereco.codigoMunicipio = this.consignatarioComponent.cidade.ID_CIDADE;
      }
      this.declaracao.consignatario.endereco.complemento = this.dadosPessoaisDTO.complemento;
      this.declaracao.consignatario.endereco.email = this.dadosPessoaisDTO.email;
      this.declaracao.consignatario.endereco.logradouro = this.dadosPessoaisDTO.logradouro;
      this.declaracao.consignatario.endereco.numero = this.dadosPessoaisDTO.numero;
      this.declaracao.consignatario.endereco.telefone = this.dadosPessoaisDTO.telefone;
      this.executarValidacoesConsignatario();
    }
  }

  private populaDestinatario() {
    if (this.destinatarioComponent != undefined && this.destinatarioComponent.destinatarioForm.dirty) {
      this.dadosPessoaisDTO = this.destinatarioComponent.destinatarioForm.value;
      this.declaracao.destinatario = new Consignatario;
      this.declaracao.destinatario.cpfCnpj = this.dadosPessoaisDTO.cpfCnpj;
      this.declaracao.destinatario.tipoPessoa = (this.declaracao.destinatario.cpfCnpj != undefined
        && this.declaracao.destinatario.cpfCnpj.length == 11) ? TipoPessoa.Fisica : TipoPessoa.Juridica;
      this.declaracao.destinatario.nomeFantasia = this.dadosPessoaisDTO.nomeFantasia;
      //this.declaracao.destinatario.rntrc = this.dadosPessoaisDTO.rntrc;
      this.declaracao.destinatario.endereco = new Endereco;
      this.declaracao.destinatario.nomeRazaoSocial = this.dadosPessoaisDTO.nome;
      this.declaracao.destinatario.endereco.bairro = this.dadosPessoaisDTO.bairro;
      this.declaracao.destinatario.endereco.cep = this.dadosPessoaisDTO.cep.replace("-", "");
      if (this.destinatarioComponent.cidade != undefined) {
        this.declaracao.destinatario.endereco.codigoMunicipio = this.destinatarioComponent.cidade.ID_CIDADE;
      }
      this.declaracao.destinatario.endereco.complemento = this.dadosPessoaisDTO.complemento;
      this.declaracao.destinatario.endereco.email = this.dadosPessoaisDTO.email;
      this.declaracao.destinatario.endereco.logradouro = this.dadosPessoaisDTO.logradouro;
      this.declaracao.destinatario.endereco.numero = this.dadosPessoaisDTO.numero;
      this.declaracao.destinatario.endereco.telefone = this.dadosPessoaisDTO.telefone;
      this.executarValidacoesDestinatario();
    }
    else {
      if (Number(TipoViagem.Padrao) == this.declaracao.frete.tipoViagem) {
        this.toastr.error(Mensagens.PREENCHA_ABA_DESTINATARIO, Mensagens.OOPS);
        this.valid = false;
      }
    }
  }

  private populaRemetente() {
    if (this.remetenteComponent != undefined) {
      this.dadosPessoaisDTO = this.remetenteComponent.remetenteForm.value;
      this.declaracao.remetente = new Consignatario;
      this.declaracao.remetente.cpfCnpj = this.dadosPessoaisDTO.cpfCnpj;
      this.declaracao.remetente.tipoPessoa = (this.declaracao.remetente.cpfCnpj != undefined
        && this.declaracao.remetente.cpfCnpj.length == 11) ? TipoPessoa.Fisica : TipoPessoa.Juridica;
      this.declaracao.remetente.nomeFantasia = this.dadosPessoaisDTO.nomeFantasia;
      //this.declaracao.remetente.rntrc = this.dadosPessoaisDTO.rntrc;
      this.declaracao.remetente.endereco = new Endereco;
      this.declaracao.remetente.nomeRazaoSocial = this.dadosPessoaisDTO.nome;
      this.declaracao.remetente.endereco.bairro = this.dadosPessoaisDTO.bairro;
      this.declaracao.remetente.endereco.cep = this.dadosPessoaisDTO.cep.replace("-", "");
      if (this.remetenteComponent.cidade != undefined) {
        this.declaracao.remetente.endereco.codigoMunicipio = this.remetenteComponent.cidade.ID_CIDADE;
      }
      this.declaracao.remetente.endereco.complemento = this.dadosPessoaisDTO.complemento;
      this.declaracao.remetente.endereco.email = this.dadosPessoaisDTO.email;
      this.declaracao.remetente.endereco.logradouro = this.dadosPessoaisDTO.logradouro;
      this.declaracao.remetente.endereco.numero = this.dadosPessoaisDTO.numero;
      this.declaracao.remetente.endereco.telefone = this.dadosPessoaisDTO.telefone;
      this.executarValidacoesRemetente();
    }
    else {
      if (Number(TipoViagem.Padrao) == this.declaracao.frete.tipoViagem) {
        this.toastr.error('Por favor, preencha a aba Remetente.', Mensagens.OOPS);
        this.valid = false;
      }
    }
  }

  private populaContratante() {
    if (this.contratanteComponent != undefined) {
      this.dadosPessoaisDTO = this.contratanteComponent.contratanteForm.value;
      this.declaracao.contratante = new Consignatario;
      this.declaracao.contratante.cpfCnpj = this.contratanteComponent.CpfCnpjInteressado;
      this.declaracao.contratante.tipoPessoa = (this.declaracao.contratante.cpfCnpj != undefined
        && this.declaracao.contratante.cpfCnpj.length == 11) ? TipoPessoa.Fisica : TipoPessoa.Juridica;
      this.declaracao.contratante.nomeFantasia = this.dadosPessoaisDTO.nomeFantasia;
      this.declaracao.contratante.nomeRazaoSocial = this.dadosPessoaisDTO.nome;
      
      if (this.dadosPessoaisDTO.rntrc && this.dadosPessoaisDTO.rntrc != "") {
        this.declaracao.contratante.rntrc = this.declaracao.frete.tipoViagem != 1 ? this.dadosPessoaisDTO.rntrc : null;
      }

      this.declaracao.contratante.endereco = new Endereco;
      this.declaracao.contratante.endereco.bairro = this.dadosPessoaisDTO.bairro;
      this.declaracao.contratante.endereco.cep = this.dadosPessoaisDTO.cep.replace("-", "");
      if (this.contratanteComponent.cidade != undefined) {
        this.declaracao.contratante.endereco.codigoMunicipio = this.contratanteComponent.cidade.ID_CIDADE;
      }
      this.declaracao.contratante.endereco.complemento = this.dadosPessoaisDTO.complemento;
      this.declaracao.contratante.endereco.email = this.dadosPessoaisDTO.email;
      this.declaracao.contratante.endereco.logradouro = this.dadosPessoaisDTO.logradouro;
      this.declaracao.contratante.endereco.numero = this.dadosPessoaisDTO.numero;
      this.declaracao.contratante.endereco.telefone = this.dadosPessoaisDTO.telefone;
      this.executarValidacoesContratante()
    }
    else {
      this.toastr.error('Por favor, preencha a aba Contratante.', Mensagens.OOPS);
      this.valid = false;
    }
  }

  private populaPagamento() {
    if (this.pagamentoComponent != undefined && this.pagamentoComponent.pagamentoForm.dirty) {
      this.pagamentoDTO = this.pagamentoComponent.pagamentoForm.value;

      this.declaracao.informacoesPagamento = new InformacoesPagamento();
      this.declaracao.informacoesPagamento.dadosBancarios = new DadosBancarios();

      this.declaracao.informacoesPagamento.formaPagamento = this.pagamentoDTO.formaPagamento;

      if (this.pagamentoComponent.banco) {
        this.declaracao.informacoesPagamento.dadosBancarios.agencia = this.pagamentoDTO.agencia;
        this.declaracao.informacoesPagamento.dadosBancarios.codigoBacen = this.pagamentoComponent.banco.CODIGO;
        this.declaracao.informacoesPagamento.dadosBancarios.conta = this.pagamentoDTO.conta;
        this.declaracao.informacoesPagamento.dadosBancarios.cpfCnpjConta = this.pagamentoDTO.cpfCnpjConta;
      } 

      this.executarValidacoesPagamento();
    } else {
      this.toastr.error('Por favor, preencha a aba Pagamento.', Mensagens.OOPS);
      this.valid = false;
    }
  }

  private populaMotorista() {
    if (this.motoristaComponent != undefined && this.motoristaComponent.motoristaForm.dirty) {
      this.motoristaDTO = this.motoristaComponent.motoristaForm.value;
      this.declaracao.frete.motorista = new Motorista;
      this.declaracao.frete.motorista.nome = this.motoristaDTO.nome;
      this.declaracao.frete.motorista.cpfCnpj = this.motoristaDTO.cpf;
      this.declaracao.frete.motorista.numeroCNH = this.motoristaDTO.cnh;
    }
  }

  private populaFrete() {
    if (this.viagemComponent != undefined && this.viagemComponent.viagemForm.dirty) {
      this.viagemDTO = this.viagemComponent.viagemForm.value;
      debugger;
      this.declaracao.frete = new Frete;
      if (TipoViagem.Padrao == this.viagemComponent.tipoViagem) {
        this.declaracao.frete.dataInicioFrete = this.viagemComponent.dataInicio;
        
        if (this.viagemDTO.cepOrigem != undefined) {
          this.declaracao.frete.cepOrigem = this.viagemDTO.cepOrigem;
        }
        
        if (this.viagemDTO.cepDestino != undefined) {
          this.declaracao.frete.cepDestino = this.viagemDTO.cepDestino;
        }

        if (this.viagemComponent.naturezaCargaSelect != undefined) {
          this.declaracao.frete.codigoNaturezaCarga = this.viagemComponent.naturezaCargaSelect.CODIGO;
        }
 
        this.declaracao.frete.pesoCarga = this.viagemDTO.pesoCarga;
      }

      this.declaracao.frete.tipoViagem = Number(this.viagemComponent.tipoViagem);
      this.declaracao.frete.dataTerminoFrete = this.viagemComponent.dataFim;
      
      if (this.viagemComponent.tipoCarga) {
        this.declaracao.frete.codigoTipoCarga = this.viagemComponent.tipoCarga.CODIGO;
      }

      this.declaracao.frete.distanciaPercorrida = this.viagemDTO.distanciaPercorrida;

      this.declaracao.frete.altodesempenho = this.viagemDTO.autodesempenho ? this.viagemDTO.autodesempenho : false;

      var checkDestinacaoComercial: CheckDestinacaoComercial;
      checkDestinacaoComercial = this.viagemDTO.destinacaoComercial;
      this.declaracao.frete.destinacaoComercial = checkDestinacaoComercial.check; 
      
      this.validFreteRetorno = this.viagemDTO.freteRetorno ? this.viagemDTO.freteRetorno : false;

      if (this.viagemDTO.cepRetorno || this.viagemDTO.distanciaRetorno) {
        this.declaracao.frete.freteRetorno = new FreteRetorno;

        if (this.viagemDTO.cepRetorno) {
          this.declaracao.frete.freteRetorno.cepRetorno = this.viagemDTO.cepRetorno;
        }
        
        if (this.viagemDTO.distanciaRetorno) {
          this.declaracao.frete.freteRetorno.distanciaRetorno = this.viagemDTO.distanciaRetorno;
        }
      } else {
        this.declaracao.frete.freteRetorno = null;
      }

      this.declaracao.valores = new Valores();
      this.declaracao.valores.valorCombustivel = this.viagemDTO.valorCombustivel;
      this.declaracao.valores.valorFrete = this.viagemDTO.valorFrete;
      this.declaracao.valores.valorTarifas = this.viagemDTO.valorTotalTarifas;
      this.declaracao.valores.valorDespesas = this.viagemDTO.valorDespesas;
      this.declaracao.valores.totalImposto = this.viagemDTO.totalImposto;
      this.declaracao.valores.totalPegadio = this.viagemDTO.totalPedagio;
      this.declaracao.valores.quantidadeTarifas = this.viagemDTO.quantidadeTarifas;
      this.executarValidacoesFrete();
    }
    else {
      this.valid = false;
      this.toastr.error('Por favor, preencha a aba Viagem.', Mensagens.OOPS);
    }
  }

  executarValidacoesFrete() {
    if (TipoViagem.Padrao == this.viagemComponent.tipoViagem && (this.declaracao.frete.cepOrigem == null || this.declaracao.frete.cepOrigem == undefined)) {
      this.valid = false;
      this.toastr.error('Por favor, informe o cep de origem.', Mensagens.OOPS);
    }
    if (TipoViagem.Padrao == this.viagemComponent.tipoViagem && (this.declaracao.frete.cepDestino == null || this.declaracao.frete.cepDestino == undefined)) {
      this.valid = false;
      this.toastr.error('Por favor, informe o cep de destino', Mensagens.OOPS);
    }
    if (TipoViagem.Padrao == this.viagemComponent.tipoViagem && (this.declaracao.frete.codigoNaturezaCarga == null || this.declaracao.frete.codigoNaturezaCarga == undefined)) {
      this.valid = false;
      this.toastr.error('Por favor, informe a natureza da carga.', Mensagens.OOPS);
    }
    if (TipoViagem.Padrao == this.viagemComponent.tipoViagem && (this.declaracao.frete.pesoCarga == null || this.declaracao.frete.pesoCarga.toString() == "")) {
      this.valid = false;
      this.toastr.error('Por favor, informe o peso da carga.', Mensagens.OOPS);
    }
    if (this.declaracao.valores.valorFrete == null || this.declaracao.valores.valorFrete.toString() == "") {
      this.valid = false;
      this.toastr.error('Por favor, informe o valor do frete.', Mensagens.OOPS);
    }
    if (this.declaracao.valores.quantidadeTarifas == null || this.declaracao.valores.quantidadeTarifas.toString() == "") {
      this.valid = false;
      this.toastr.error('Por favor, informe a quantidade de tarifas.', Mensagens.OOPS);
    }
    if (this.declaracao.valores.valorTarifas == null || this.declaracao.valores.valorTarifas.toString() == "") {
      this.declaracao.valores.valorTarifas = 0;
      // this.valid = false;
      // this.toastr.error('Por favor, informe o valor total de tarifas.', Mensagens.OOPS);
    }
    if (this.declaracao.valores.totalImposto == null || this.declaracao.valores.totalImposto.toString() == "") {
      this.declaracao.valores.totalImposto = 0;
    }

    if (this.declaracao.frete.codigoTipoCarga == null || this.declaracao.frete.codigoTipoCarga == 0) {
      this.valid = false;
      this.toastr.error('Por favor, informe um tipo de carga.', Mensagens.OOPS);
    }

    if (!this.declaracao.frete.distanciaPercorrida == null || this.declaracao.frete.distanciaPercorrida.toString() == "") {
      this.valid = false;
      this.toastr.error('Por favor, informe a distância percorrida.', Mensagens.OOPS);
    } 

    if (this.validFreteRetorno && (this.declaracao.frete.freteRetorno.cepRetorno == null || this.declaracao.frete.freteRetorno.cepRetorno.toString() == "")) {
      this.valid = false;
      this.toastr.error('Por favor, informe o cep de retorno.', Mensagens.OOPS);
    }

    if (this.validFreteRetorno && (this.declaracao.frete.freteRetorno.distanciaRetorno == null)) {
      this.valid = false;
      this.toastr.error('Por favor, informe a distância de retorno.', Mensagens.OOPS);
    }
  }

  executarValidacoesPagamento() {
    if ((this.declaracao.informacoesPagamento.formaPagamento != 1 && this.declaracao.informacoesPagamento.formaPagamento != 5)) {
      if (!this.declaracao.informacoesPagamento.dadosBancarios.codigoBacen || this.declaracao.informacoesPagamento.dadosBancarios.codigoBacen.toString() == "") {
        this.valid = false;
        this.toastr.error('Por favor, informe o banco.', Mensagens.OOPS);
      }
    }   
    if (this.declaracao.informacoesPagamento.formaPagamento.toString() == "") {
      this.valid = false;
      this.toastr.error('Por favor, informe a forma de pagamento.', Mensagens.OOPS);
    }
    if ((this.declaracao.informacoesPagamento.formaPagamento != 1 && this.declaracao.informacoesPagamento.formaPagamento != 5) && this.declaracao.informacoesPagamento.dadosBancarios.cpfCnpjConta.toString() == "") {
      this.valid = false;
      this.toastr.error('Por favor, informe o CPF/CNPJ da conta.', Mensagens.OOPS);
    }
    if ((this.declaracao.informacoesPagamento.formaPagamento != 1 && this.declaracao.informacoesPagamento.formaPagamento != 5) && this.declaracao.informacoesPagamento.dadosBancarios.agencia.toString() == "") {
      this.valid = false;
      this.toastr.error('Por favor, informe a agência para pagamento.', Mensagens.OOPS);
    }
    if ((this.declaracao.informacoesPagamento.formaPagamento != 1 && this.declaracao.informacoesPagamento.formaPagamento != 5) && this.declaracao.informacoesPagamento.dadosBancarios.conta.toString() == "") {
      this.valid = false;
      this.toastr.error('Por favor, informe a conta para pagamento.', Mensagens.OOPS);
    }
  }

  executarValidacoesTransportador() {
    if (this.declaracao.frete.proprietario.cpfCnpj.toString() == "") {
      this.toastr.error('Por favor, informe o CPF/CNPJ do transportador.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.frete.proprietario.nomeRazaoSocial.toString() == "") {
      this.toastr.error('Por favor, informe a razão social do transportador.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.frete.proprietario.endereco.cep.toString() == "") {
      this.toastr.error('Por favor, informe o CEP do transportador.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.frete.proprietario.endereco.logradouro.toString() == "") {
      this.toastr.error('Por favor, informe o logradouro do transportador.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.frete.proprietario.endereco.codigoMunicipio == undefined || this.declaracao.frete.proprietario.endereco.codigoMunicipio.toString() == "") {
      this.toastr.error('Por favor, informe o município do transportador.', Mensagens.OOPS);
      this.valid = false;
    }
  }

  executarValidacoesContratante() {
    if (this.declaracao.contratante.cpfCnpj.toString() == "") {
      this.toastr.error('Por favor, informe o CPF/CNPJ do contratante.', Mensagens.OOPS);
      this.valid = false;
    }

    if (TipoViagem.Padrao != this.viagemComponent.tipoViagem && (!this.declaracao.contratante.rntrc || this.declaracao.contratante.rntrc == "")) {
      this.toastr.error('Por favor, informe o RNTRC do contratante.', Mensagens.OOPS);
      this.valid = false;
    }

    if (this.declaracao.contratante.nomeRazaoSocial.toString() == "") {
      this.toastr.error('Por favor, informe a razão social do contratante.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.contratante.endereco.cep.toString() == "") {
      this.toastr.error('Por favor, informe o CEP do contratante.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.contratante.endereco.logradouro.toString() == "") {
      this.toastr.error('Por favor, informe o logradouro do contratante.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.contratante.endereco.codigoMunicipio == undefined || this.declaracao.contratante.endereco.codigoMunicipio.toString() == "") {
      this.toastr.error('Por favor, informe o município do contratante.', Mensagens.OOPS);
      this.valid = false;
    }
  }

  executarValidacoesRemetente() {
    if (TipoViagem.TAC_Agregado == this.viagemComponent.tipoViagem) {
      return;
    }
    if (this.declaracao.remetente.cpfCnpj.toString() == "") {
      this.toastr.error('Por favor, informe o CPF/CNPJ do remetente.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.remetente.nomeRazaoSocial.toString() == "") {
      this.toastr.error('Por favor, informe a razão social do remetente.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.remetente.endereco.cep.toString() == "") {
      this.toastr.error('Por favor, informe o CEP do remetente.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.remetente.endereco.logradouro.toString() == "") {
      this.toastr.error('Por favor, informe o logradouro do remetente.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.remetente.endereco.codigoMunicipio == undefined || this.declaracao.remetente.endereco.codigoMunicipio.toString() == "") {
      this.toastr.error('Por favor, informe o município do remetente.', Mensagens.OOPS);
      this.valid = false;
    }
  }

  executarValidacoesDestinatario() {
    if (TipoViagem.TAC_Agregado == this.viagemComponent.tipoViagem) {
      return;
    }
    if (this.declaracao.destinatario.cpfCnpj.toString() == "") {
      this.toastr.error('Por favor, informe o CPF/CNPJ do destinatario.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.destinatario.nomeRazaoSocial.toString() == "") {
      this.toastr.error('Por favor, informe a razão social do destinatario.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.destinatario.endereco.cep.toString() == "") {
      this.toastr.error('Por favor, informe o CEP do destinatario.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.destinatario.endereco.logradouro.toString() == "") {
      this.toastr.error('Por favor, informe o logradouro do destinatario.', Mensagens.OOPS);
      this.valid = false;
    }
    if (this.declaracao.destinatario.endereco.codigoMunicipio == undefined || this.declaracao.destinatario.endereco.codigoMunicipio.toString() == "") {
      this.toastr.error('Por favor, informe o município do destinatario.', Mensagens.OOPS);
      this.valid = false;
    }
  }

  executarValidacoesConsignatario() {
    if (TipoViagem.TAC_Agregado == this.viagemComponent.tipoViagem) {
      return;
    }
    if (this.declaracao.consignatario.cpfCnpj != "" || this.declaracao.consignatario.nomeRazaoSocial != "") {
      if (this.declaracao.consignatario.cpfCnpj.toString() == "") {
        this.toastr.error('Por favor, informe o CPF/CNPJ do consignatário.', Mensagens.OOPS);
        this.valid = false;
      }
      if (this.declaracao.consignatario.nomeRazaoSocial.toString() == "") {
        this.toastr.error('Por favor, informe a razão social do consignatário.', Mensagens.OOPS);
        this.valid = false;
      }
      if (this.declaracao.consignatario.endereco.cep.toString() == "") {
        this.toastr.error('Por favor, informe o CEP do consignatário.', Mensagens.OOPS);
        this.valid = false;
      }
      if (this.declaracao.consignatario.endereco.logradouro.toString() == "") {
        this.toastr.error('Por favor, informe o logradouro do consignatário.', Mensagens.OOPS);
        this.valid = false;
      }
      if (this.declaracao.consignatario.endereco.codigoMunicipio == undefined || this.declaracao.consignatario.endereco.codigoMunicipio.toString() == "") {
        this.toastr.error('Por favor, informe o município do consignatário.', Mensagens.OOPS);
        this.valid = false;
      }
    }
  }

  onError(serviceReturn) {
    this.errors = Object.assign([], serviceReturn.error.errors);
    this.toastr.error("Não foi possível realizar a declaração", Mensagens.OOPS)
  }

  onSaveComplete(result): void {
    if (result.Retorno.Sucesso) {
      this.router.navigate(['consulta-ciot', result.Retorno.CIOT, result.Retorno.SenhaAlteracao, this.motoristaComponent.motoristaForm.value.cpf, this.contratanteComponent.contratanteForm.value.cpfCnpj, this.viagemComponent.viagemForm.value.cepOrigem, this.viagemComponent.viagemForm.value.cepDestino, true], { skipLocationChange: true });
    } else {
      if (result.Retorno.Excecao != null) {
        let msgSplited = result.Retorno.Excecao.Mensagem.split("\r\n");
        if (msgSplited != null && msgSplited != undefined && msgSplited.length > 1) {
          msgSplited.pop();
          msgSplited.forEach(msgSplited => {
            this.toastr.error(msgSplited, Mensagens.OOPS);
          });
        } else {
          this.toastr.error(result.Retorno.Excecao.Mensagem, Mensagens.OOPS);
        }
      } else {
        this.toastr.error(result.Mensagem, Mensagens.OOPS);
      }
    }
  }

  voltar() {
    this.router.navigate(['/'], { skipLocationChange: true });
  }
}

export class CheckDestinacaoComercial {
  name: string;
  id: number;
  check: boolean;
}