import {AfterViewInit, Component, ElementRef, OnInit, ViewChildren} from '@angular/core';
import {FormBuilder, FormControlName, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";

import {Observable} from "rxjs/Observable";
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

import {GenericValidator} from "../../commom/generic.form.validator";
import {Ciot, CiotRetificacao} from "../../ciot/models/ciot"
import {CiotService} from "../../ciot/services/ciot.service";
import {Subscription} from 'rxjs/Subscription';
import {DatePipe} from '@angular/common';
import {Veiculo} from '../models/veiculo';
import {ToastsManager} from 'ng2-toastr';
import {
    RetificarOperacaoTransporteRequest,
    RetificarOperacaoTransporteResponse
} from '../models/retificacao/retificar-operacao-transporte';
import {DadosRetificar} from '../models/retificacao/dados-retificar';
import {Cidade} from '../models/cidade';
import {NaturezaCarga} from '../models/natureza-carga';
import { objRetificar } from '../models/retificacao/obj-retificar';
import {RetornoNatureza} from '../models/natureza-carga';
import {ConsultarSituacaoCiotRequest} from '../models/consulta-ciot/consultar-situacao-ciot-request';
import {CiotResponse} from '../models/consulta-ciot/consulta-ciot-response';
import {SelectedAutocompleteItem} from 'ng-auto-complete';
import {AutoCompleteService} from '../services/autocomplete.service';
import {Mensagens, TipoViagem} from '../util/enums';
import {AutoCompleteNaturezaService} from '../services/autocomplete-natureza.service';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';

import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import {defineLocale, listLocales} from 'ngx-bootstrap/chronos'
import { ptBrLocale } from 'ngx-bootstrap/locale';

@Component({
  selector: 'app-retificacao',
  templateUrl: './retificacao.component.html',
  styles: [],
})
export class RetificacaoComponent implements OnInit, AfterViewInit {

    locale = ptBrLocale;
    locales = listLocales();

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  bsConfig: Partial<BsDatepickerConfig>;

  public errors: any[] = [];
  public retificacaoForm: FormGroup;
  public veiculoForm: FormGroup;
  public ciotRetificacao: CiotRetificacao;
  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  public sub: Subscription;
  public value: Date = new Date(2000, 2, 10);
  public veiculoList: Array<Veiculo> = [];
  public veiculo: Veiculo;
  private request: RetificarOperacaoTransporteRequest = new RetificarOperacaoTransporteRequest();
  private response: RetificarOperacaoTransporteResponse = new RetificarOperacaoTransporteResponse();
  ciotId: string;
  senha: string;
  // cidadeOrigem: Cidade = new Cidade();
  // cidadeDestino: Cidade = new Cidade();
  public naturezaCarga: NaturezaCarga;
  public naturezaCargaSelect: RetornoNatureza;
  private ciotResponse: CiotResponse;
  public codigoCidadeOrigem: string = "";
  public codigoCidadeDestino: string = "";
  public codigoNatureza: string = "";
  public tipoViagemDescricao: string;
  private dtInicio: Date;
  private dtFinal: Date;

  // AutoComplete fields
  cidadeList: Array<Cidade> = [];
  naturezaCargaList: Array<NaturezaCarga> = [];

  noResultOrigem = false;
  noResultDestino = false;
  noResultNatureza = false;

  cidadeOrigemCompleterText: string;
  cidadeDestinoCompleterText: string;
  naturezaCompleterText: string;
  public tipoViagem: string = TipoViagem.Padrao;

  dataInicio = new Date();
  dataFim = new Date();
  public naoPodeEditar : boolean;

  constructor(private fb: FormBuilder,
    private service: CiotService,
    private autoCompleteService: AutoCompleteService,
    private autoCompleteNaturezaService: AutoCompleteNaturezaService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastsManager,
    private localeService: BsLocaleService) {

    defineLocale("ptbr", ptBrLocale)

      this.bsConfig = Object.assign({}, { containerClass: "theme-red" });
      this.localeService.use("ptbr");

    // this.service.consultarCidades("")
    // .subscribe(
    //   result => {
    //     this.cidadeList = result;
    //   },
    //   error => { }
    // );
    this.service.consultarNaturezaCarga("")
      .subscribe(
        result => {
          this.naturezaCargaList = result;
        },
        error => { }
      );
    this.validationMessages = {
      // cidadeOrigem: {
      //   required: 'A cidade origem do contratante é obrigatório.'
      // },
      // cidadeDestino: {
      //   required: 'A cidade destino é obrigatório.',
      // },
      naturezaCarga: {
        required: 'A natureza da carga é obrigatório.',
      },
      valorFrete: {
        required: 'O valor do frete é obrigatório.',
      },
      quantidadeTarifas: {
        required: 'A quantidade de tarifas é obrigatório.',
      },
      valorTotalTarifas: {
        required: 'O valor total das tarifas é obrigatório.',
      },
      placa: {
        required: 'A placa do veículo é obrigatória.',
      },
      rntrc: {
        required: 'RNTRC do veículo é obrigatório.',
      },
      pesoCarga: {
        required: 'O peso da carga é obrigatório.',
      },
    };

    this.genericValidator = new GenericValidator(this.validationMessages);
    this.veiculo = new Veiculo;

  }

  // AutoComplete métodos
  // typeaheadNoResultsOrigem(event: boolean): void {
  //   this.noResultOrigem = event;
  //   this.cidadeOrigem = undefined;
  // }

  // typeaheadNoResultsDestino(event: boolean): void {
  //   this.noResultDestino = event;
  //   this.cidadeDestino = undefined;
  // }

  typeaheadNoResultsNatureza(event: boolean): void {
    this.noResultNatureza = event;
    this.naturezaCarga = undefined;
  }

  // onSelectCidadeOrigem(event: TypeaheadMatch): void {
  //   this.cidadeOrigem = event.item;
  // }

  // onSelectCidadeDestino(event: TypeaheadMatch): void {
  //   this.cidadeDestino = event.item;
  // }

  onSelectNatureza(event: TypeaheadMatch): void {
    this.naturezaCargaSelect = event.item;
  }

  consultarCiot(ciotId: string, senha: string) {
    this.ciotRetificacao = new CiotRetificacao();
    this.ciotRetificacao.ciot = new Ciot();
    let a = new ConsultarSituacaoCiotRequest();
    a.ciot = ciotId;
    a.senhaAlteracao = senha;

    let ciotResponse;
    this.service.consultarCiot(a)
      .subscribe(
        response => this.preencherDadosInformativos(response, a.ciot, a.senhaAlteracao),
        error => { this.onError(error) }
      );
  }

  preencherDadosInformativos(ciotResponse: CiotResponse, ciotId: string, senha: string): void {
    this.ciotRetificacao.ciot.totalImposto = ciotResponse.Retorno.TotalImposto;
    this.ciotRetificacao.ciot.rntrc = ciotResponse.Retorno.RntrcProprietario;
    this.ciotRetificacao.ciot.contratado = ciotResponse.Retorno.NomeProprietario;
    this.ciotRetificacao.ciot.valorFrete = ciotResponse.Retorno.ValorFrete;
    this.ciotRetificacao.ciot.totalPedagio = ciotResponse.Retorno.TotalPegadio;
    this.ciotRetificacao.ciot.encerrada = ciotResponse.Retorno.Encerrado;
    this.ciotRetificacao.ciot.tipoViagem = ciotResponse.Retorno.TipoViagem;
    this.tipoViagemDescricao = Number(TipoViagem.Padrao) == ciotResponse.Retorno.TipoViagem ? Mensagens.PADRAO : Mensagens.TAC_AGREGADO;

    var objRetificar: objRetificar;
    objRetificar.ciotId = ciotId;
    objRetificar.senha = senha;

    this.service.consultarRetificacao(objRetificar)
      .subscribe(
        result => this.preencherDadosRetificar(result))
    error => { this.onError(error) }
  }

  preencherDadosRetificar(dadosRetificar: DadosRetificar): void {
    this.retificacaoForm.markAsDirty;
    // dadosRetificar.dataInicioViagem = new DatePipe('en-US').transform(dadosRetificar.dataInicioViagem, "dd/MM/yyyy");
    // dadosRetificar.ciot.dtTerminoFrete = new DatePipe('en-US').transform(dadosRetificar.ciot.dtTerminoFrete, "dd/MM/yyyy");

    this.ciotRetificacao.ciot.ciot = dadosRetificar.ciot;
    this.codigoCidadeOrigem = dadosRetificar.codigoMunicipioOrigem
    this.codigoCidadeDestino = dadosRetificar.codigoMunicipioDestino;
    this.codigoNatureza = dadosRetificar.codigoNaturezaCarga;

    // this.cidadeDestino.Retorno.ID_CIDADE = Number(dadosRetificar.codigoMunicipioDestino);
    this.dataInicio = new Date(dadosRetificar.dataInicioViagem);
    this.dataFim = new Date(dadosRetificar.dataFimViagem);
    // this.naturezaCarga.id = dadosRetificar.codigoNaturezaCarga;
    this.ciotRetificacao.ciot.pesoCarga = dadosRetificar.pesoCarga;
    this.ciotRetificacao.ciot.senha = dadosRetificar.senhaAlteracao;
    this.ciotRetificacao.ciot.tipoViagem = dadosRetificar.tipoViagem;
    this.tipoViagem = dadosRetificar.tipoViagem.toString();
    if (TipoViagem.TAC_Agregado == this.tipoViagem) {
      this.retificacaoForm.controls['pesoCarga'].disable();
    }
    this.ciotRetificacao.veiculos = dadosRetificar.veiculos;

    this.ciotRetificacao.ciot.quantidadeTarifas = dadosRetificar.quantidadeTarifas;
    this.ciotRetificacao.ciot.valorTotalTarifas = dadosRetificar.valorTarifas;

    this.retificacaoForm.setValue(this.ciotRetificacao.ciot);

    this.initiateVeiculo(dadosRetificar.veiculos != undefined && dadosRetificar.veiculos.length > 0 ? dadosRetificar.veiculos[0].rntrc : "");

    dadosRetificar.veiculos.forEach(v => {
      let veiculo = Object.assign({}, this.veiculo, v);
      this.veiculoList.push(veiculo);
    });
    var dataLimiteDeRetificarDados = new Date(this.dataInicio.toString());
    dataLimiteDeRetificarDados.setHours(24);
    var dataAtual =  new Date(Date.now());
    this.naoPodeEditar = dataLimiteDeRetificarDados < dataAtual;
    if(this.naoPodeEditar){
      this.retificacaoForm.controls['quantidadeTarifas'].disable();
      this.retificacaoForm.controls['valorTotalTarifas'].disable();
      this.retificacaoForm.controls['pesoCarga'].disable();
    }

    // if (this.codigoCidadeOrigem != undefined && this.codigoCidadeOrigem != "") {
    //   this.service.consultarCidadesByIBGE(Number(this.codigoCidadeOrigem))
    //     .subscribe(
    //       result => {
    //         this.cidadeOrigem = result[0];
    //         this.cidadeOrigemCompleterText = this.cidadeOrigem.Retorno.UF;
    //       })
    //   error => { this.onError(error) }
    // }

    // if (this.codigoCidadeDestino != undefined && this.codigoCidadeDestino != "") {
    //   this.service.consultarCidadesByIBGE(Number(this.codigoCidadeDestino))
    //     .subscribe(
    //       result => {
    //         this.cidadeDestino = result[0];
    //         this.cidadeDestinoCompleterText = this.cidadeDestino.Retorno.UF;
    //       })
    //   error => { this.onError(error) }
    // }

    if (this.codigoNatureza != undefined && this.codigoNatureza != "") {
      this.service.consultarNaturezaCargaById(this.codigoNatureza)
        .subscribe(
          result => {
            this.naturezaCarga = result[0];
            this.naturezaCompleterText = this.naturezaCarga.Retorno.DESCRICAO;
          })
      error => { this.onError(error) }
    }
  }

  private initiateVeiculo(rntrc: string) {
    var veiculo = new Veiculo();
    veiculo.rntrc = rntrc;
    veiculo.placa = "";
    this.veiculoForm.setValue(veiculo);
  }

  adicionarVeiculo() {
    if (this.veiculoForm.dirty && this.veiculoForm.valid) {
      this.veiculo = this.veiculoForm.value;
      this.veiculo.placa = this.veiculo.placa.toUpperCase();

      if (this.validarVeiculo(this.veiculo)) return;

      this.veiculoList.push(this.veiculo);
      this.veiculoForm.reset();
      this.initiateVeiculo(this.veiculo.rntrc);
    }
  }

  validarVeiculo(veiculo: Veiculo): boolean {
    var regexp = new RegExp(/[a-zA-Z]{3}-?\d{4}/),
      test = regexp.test(veiculo.placa);
    if (!test || veiculo.placa.length > 8) {
      this.toastr.error("A placa informada é inválida.", Mensagens.OOPS);
      return true;
    }

    for (let index = 0; index < this.veiculoList.length; index++) {
      if (this.veiculoList[index].placa == veiculo.placa) {
        this.toastr.error("Este veículo já foi adicionado", "Oops!");
        return true;
      }
    }
  }

  removerVeiculo(veiculo) {
    this.veiculoList
    for (var i = 0; i < this.veiculoList.length; i++) {
      if (this.veiculoList[i].placa == veiculo.placa) {
        var index = this.veiculoList.indexOf((this.veiculoList[i]));
        this.veiculoList.splice(index, 1)
      }
    }
  }

  ngOnInit() {
    this.retificacaoForm = this.fb.group({
      ciot: ['', [Validators.required]],

      rntrc: ['',],
      contratado: ['',],

      valorFrete: ['',],
      totalImposto: ['',],
      totalPedagio: ['',],
      tipoViagem: ['',],
      senha: ['',],
      encerrada: ['',],
      pesoCarga: ['',],
      quantidadeTarifas: ['',],
      valorTotalTarifas: ['',],

      // documentoContratante: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(14)]],
    });

    this.veiculoForm = this.fb.group({
      placa: ['', Validators.required],
      rntrc: ['', Validators.required],
    });
    this.ciotRetificacao = new CiotRetificacao();

    this.sub = this.route.params.subscribe(
      params => {
        this.ciotId = params['ciot'];
        this.senha = params['senha'];
        this.consultarCiot(this.ciotId, this.senha);
      });

  }

  // consultarCidadesDestino(nome: string): void {
  //   this.service.consultarCidades(nome)
  //     .subscribe(
  //       result => {
  //         this.cidadeList = result;
  //         if (this.cidadeDestinoCompleterText != undefined) {
  //           // this.cidadeDestinoCompleter.SetValues("cidadeDestinoCompleter", result);
  //         }
  //       },
  //       error => { }
  //     );

  // }

  // Selected(item: SelectedAutocompleteItem) {
  //   if (item == undefined || item.item == null) {
  //     return;
  //   }
  //   this.cidadeOrigem = item.item.original;
  // }

  // onKeypressCidadeDestino(event) {
  //   if (event.target.value.length >= 3 && event.target.value.replace(/\s/g, "") != "") {
  //     this.consultarCidadesDestino(event.target.value);
  //   }
  // }

  ngAfterViewInit(): void {
    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    Observable.merge(...controlBlurs).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.retificacaoForm);
    });
  }

  retificar() {
    this.request.ciot = this.retificacaoForm.value.ciot;
    if (Number(TipoViagem.Padrao) == this.ciotRetificacao.ciot.tipoViagem) {
      this.request.pesoCarga = this.retificacaoForm.value.pesoCarga;
      // this.request.codigoMunicipioDestino = this.cidadeDestino.Retorno.ID_CIDADE;
      // this.request.codigoMunicipioOrigem = this.cidadeOrigem.Retorno.ID_CIDADE;
      //  = this.retificacaoForm.value.contratado;

      this.request.dataInicioViagem = this.dataInicio;
      this.request.dataFimViagem = this.dataFim;

      this.request.ciot = this.retificacaoForm.value.ciot;
      this.request.codigoNaturezaCarga = this.naturezaCargaSelect.CODIGO;
    }
    this.request.quantidadeTarifas = this.retificacaoForm.value.quantidadeTarifas;
    this.request.senhaAlteracao = this.retificacaoForm.value.senha;
    this.request.valorTarifas = this.retificacaoForm.value.valorTotalTarifas;
    this.request.veiculos = this.veiculoList;

    this.service.retificarOperacaoTransporte(this.request)
      .subscribe(
        response => this.onSaveComplete(response),
        error => { this.onError(error) }
      );
  }

  onError(serviceReturn) {
    this.errors = Object.assign([], serviceReturn.error.errors);
  }

  onSaveComplete(response: RetificarOperacaoTransporteResponse): void {
    if (response.Retorno.Sucesso) {
      this.router.navigate(['/']).then(() => this.toastr.success(Mensagens.CIOT_RETIFICADO_SUCESSO, Mensagens.SUCESSO));
    } else {
      if (response.Retorno.Excecao != null) {
        this.toastr.error(response.Retorno.Excecao.Mensagem, Mensagens.OOPS)
      } else {
        this.toastr.error(response.Mensagem, Mensagens.OOPS)

      }
    }
  }

  voltar() {
    this.router.navigate(['/']);
  }

  omitSpecialChar(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
