import { Excecao } from "../common/excecao";

export class EncerrarOperacaoTransporteRequest {
    ciot: string;
    PesoCargaTotal : number;
    viagensOperacaoTransporte: ViagensOperacaoTransporte[];
    valoresEfetivos: ValoresEfetivos;
    senhaAlteracao: string;
}

export class ViagensOperacaoTransporte {
    codigoMunicipioOrigem: number;
    codigoMunicipioDestino: number;
    codigoNaturezaCarga: string;
    pesoCarga: number;
    quantidadeViagens: number;
}

export class ValoresEfetivos {
    valorFrete: number;
    valorCombustivel: number;
    valorDespesas: number;
    totalImposto: number;
    totalPegadio: number;
    quantidadeTarifas: number;
    valorTarifas: number;
}

export interface EncerrarOperacaoTransporteResponse { 
    Retorno: DadosRetornoEncerrarSucess;
    Sucesso: boolean;
    Mensagem: string;
}

class DadosRetornoEncerrarSucess {
    CIOT: string;
    Excecao: Excecao;
    ProtocoloEncerramento: string;
    DataEncerramento: Date;
    Sucesso: boolean;
}

